import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import queryString from "querystring";

import {
	Button,
	ConfigProvider,
	Dropdown,
	Menu,
	Pagination,
	Row,
	Table,
} from "antd";
import moment from "moment";

import * as XLSX from "xlsx";

import orderApi from "../../apis/Order.api";
import queryLogApi from "../../apis/QueryLog.api";
import { toastAlert } from "../../components/AlertComponent";
import LinkComponent from "../../components/layout/LinkComponent";
import ReportDateSearchComponent from "../../components/report/ReportDateSearchComponent";
import ReportStatisticsComponent from "../../components/report/ReportStatisticsComponent";
import { setLoading } from "../../redux/store/common/commonDuck";
import ApiService from "../../utils/ApiService";
import status from "../../utils/status";

const api = new ApiService();

const Reports = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [mediaResult, setMediaResult] = useState(null);
	const [navClicked, setNavClicked] = useState("1");
	const [groupType, setGroupType] = useState("sale"); // sale, click, suggest;
	const [dateType, setDateType] = useState("today");
	const queryJson = queryString.parse(
		history.location.search?.replace("?", "")
	);

	useEffect(() => {
		const level = queryJson?.level;
		const saleGroup = queryJson?.sale;
		if (!!level && level === "settlement") {
			if (navClicked !== "2") {
				setNavClicked("2");
			}
		} else if (saleGroup) {
			if (saleGroup === "sale" && groupType !== "sale") {
				setGroupType("sale");
			} else if (saleGroup === "click" && groupType !== "click") {
				setGroupType("click");
			} else if (saleGroup === "suggest" && groupType !== "suggest") {
				setGroupType("suggest");
			}
		}
	}, [queryJson]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	const [searchOptions, setSearchOptions] = useState({
		// startDate: moment().subtract(1, 'months').startOf('day'),
		// startDate: moment().startOf("month"),
		startDate: moment().startOf("day"),
		endDate: moment().endOf("day"),
	});

	const [statistics, setStatistics] = useState({
		click: 0,
		purchases_num: 0,
		purchases_complete_num: 0,
		cvr: 0.0,
		combined_amount: 0,
		expected_profit_amount: 0,
		profit_amount: 0,
		click_count: 0,
		click_profit: 0,
		recommend_count: 0,
		recommend_profit: 0,
	});
	const [orderList, setOrderList] = useState([]);
	const [orderTotalList, setOrderTotalList] = useState([]);
	const [totalSize, setTotalSize] = useState(0);
	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [offset, setOffset] = useState(0);
	const [orderType, setOrderType] = useState("created_at");
	const [orderBy, setOrderBy] = useState("desc");
	const [updateDate, setUpdateDate] = useState(moment());

	const [detail, setDetail] = useState(false);
	const saleColumns = [
		{
			title: "브랜드명",
			dataIndex: "product_brand_name",
			key: "product_brand_name",
			width: 100,
			// sorter: (a, b) => {
			// },
		},
		{
			title: "상품명",
			width: 170,
			dataIndex: "product_name",
			key: "product_name",
			render: (value) => {
				return (
					<span
						style={{
							width: "100%",
							textAlign: "center",
							display: "block",
							whiteSpace: "nowrap",
							overflow: "hidden",
							maxWidth: 170,
							textOverflow: "ellipsis",
						}}
					>
						{value}
					</span>
				);
			},
		},
		{
			title: "옵션명",
			width: 150,
			dataIndex: "option_title",
			key: "option_title",
			render: (value, record) => {
				if (record.option_id) {
					return (
						<span
							style={{
								width: "100%",
								textAlign: "left",
								display: "block",
								whiteSpace: "nowrap",
								overflow: "hidden",
								maxWidth: 150,
								textOverflow: "ellipsis",
							}}
						>
							{record.option_title}
						</span>
					);
				}
			},
		},
		// {
		//     title: "상세주문번호",
		//     dataIndex: 'order_detail_id',
		//     key: 'order_detail_id'
		// },
		{
			title: "구매일자",
			dataIndex: "created_at",
			key: "created_at",
			width: 150,
			// sorter: (a, b) => {
			// },
			render: (value) => {
				if (value) {
					return moment(value).format("YYYY.MM.DD HH:mm");
				} else return "-";
			},
		},
		{
			title: "구매확정일자",
			dataIndex: "complete_date",
			key: "complete_date",
			width: 150,
			render: (value) => {
				if (value) {
					return moment(value).format("YYYY.MM.DD HH:mm");
				} else return "-";
			},
		},
		// {
		//     title: "ID",
		//     dataIndex: 'created_by_name',
		//     key: 'created_by_name',
		//     render: (value) => {
		//         // if (value.trim().length < 5) {
		//         // return value.trim().replace(/(?<=.{2})./gi, "*")
		//         return value?.trim().slice(0, 2) + "****"
		//         // }
		//         // else {
		//         //     // return value.trim().replace(/(?<=.{4})./gi, "*")
		//         //     return value.trim().slice(0, 4) + "*****"
		//         // }
		//     }
		// },
		// {
		// 	title: "이름",
		// 	dataIndex: "name",
		// 	key: "name",
		// 	render: (value) => {
		// 		let name = value?.trim().replace(" ", "");
		// 		if (name) {
		// 			if (name?.length < 3) {
		// 				// return value.replace(/(?<=.{1})./gi, "*")
		// 				return name?.slice(0, 1) + "*";
		// 			} else {
		// 				return name?.slice(0, 1) + "*" + name[name?.length - 1];
		// 			}
		// 		}
		// 	},
		// },
		{
			title: "주문/배송상태",
			dataIndex: "order_detail_status",
			key: "order_detail_status",
			render: (value) => {
				return status.matchingOrderStatus[value];
			},
		},
		{
			title: "판매금액(원)",
			dataIndex: "total_price",
			key: "total_price",
			render: (value) => {
				return value.toLocaleString();
			},
		},
		{
			title: "수수료율",
			dataIndex: "commission_rate",
			key: "commission_rate",
			render: (value) => {
				if (value) {
					return `${value}%`;
				}
			},
		},
		{
			title: "수익(G)",
			dataIndex: "",
			key: "",
			render: (value, record) => {
				// if (record.order_detail_status === "complete" && record.commission_rate) {

				const settleStatus = record?.is_settlement;
				if (
					record.order_detail_status === "cancel_request" ||
					record.order_detail_status === "cancel_complete" ||
					record.order_detail_status === "return_request" ||
					record.order_detail_status === "return_receive" ||
					record.order_detail_status === "return_refusal" ||
					record.order_detail_status === "return_collection_complete" ||
					record.order_detail_status === "return_collection_fail" ||
					record.order_detail_status === "return_delivery" ||
					record.order_detail_status === "return_delivery_complete" ||
					record.order_detail_status === "return_complete"
				) {
					return "-";
				} else {
					if (record?.commission_rate) {
						if (settleStatus || settleStatus === null) {
							let profit = Math.round(
								record.total_price * (record.commission_rate / 100)
							);
							if (profit >= 50000) {
								return (
									<span style={{ fontWeight: "bold", color: "#117ffa" }}>
										{(profit * 10).toLocaleString()}
									</span>
								);
							} else
								return (
									<span style={{ color: "#117ffa" }}>
										{(profit * 10).toLocaleString()}
									</span>
								);
						} else return "-";
					}
				}
			},
		},
		// {
		//     title: `${orderList[0]?.vat ? "부가가치세" : "원천세"}`,
		//     dataIndex: 'vat',
		//     key: `vat`,
		//     render: (value, record) => {
		//         const settleStatus = record?.is_settlement;
		//         if (record.order_detail_status === "cancel_request"
		//             || record.order_detail_status === "cancel_complete"
		//             || record.order_detail_status === "return_complete"
		//         ) {
		//             return "-"
		//         } else {
		//             if (value) {
		//                 if (record?.commission_rate) {
		//                     let profit = record.total_price * (record.commission_rate / 100);
		//                     if (settleStatus || settleStatus === null) {
		//                         return Math.round(profit * 0.1).toLocaleString()
		//                     } else return "-"
		//                 } else return "-"
		//             } else {
		//                 if (record?.commission_rate) {
		//                     let profit = record.total_price * (record.commission_rate / 100);
		//                     if (settleStatus || settleStatus === null) {
		//                         return Math.round(profit * 0.033).toLocaleString()
		//                     } else return "-"
		//                 } else return "-"
		//             }
		//         }
		//     }
		// },
		// {
		//     title: `정산금액`,
		//     dataIndex: 'vat',
		//     key: `vat`,
		//     render: (value, record) => {
		//         const settleStatus = record?.is_settlement;
		//         if (record.order_detail_status === "cancel_request"
		//             || record.order_detail_status === "cancel_complete"
		//             || record.order_detail_status === "return_complete"
		//         ) {
		//             return "-"
		//         } else {
		//             if (value) {
		//                 if (record?.commission_rate) {
		//                     let profit = record.total_price * (record.commission_rate / 100);
		//                     profit += profit * 0.1;
		//                     // return Math.round(profit).toLocaleString()
		//                     if (settleStatus || settleStatus === null) {
		//                         return <span style={{color: "#117ffa"}}>
		//                             {Math.round(profit).toLocaleString()}
		//                         </span>
		//                     } else return "-"
		//                 } else return "-"
		//             } else {
		//                 if (record?.commission_rate) {
		//                     let profit = record.total_price * (record.commission_rate / 100);
		//                     profit -= profit * 0.033;
		//                     // return Math.round(profit).toLocaleString()
		//                     if (settleStatus || settleStatus === null) {
		//                         return <span style={{color: "#117ffa"}}>
		//                             {Math.round(profit).toLocaleString()}
		//                         </span>
		//                     } else return "-"
		//                 } else return "-"
		//             }
		//         }
		//     }
		// },
		{
			title: "현황",
			dataIndex: "",
			key: "",
			// filters: [
			//     {
			//         text: '정산완료',
			//         value: 'success',
			//     },
			//     {
			//         text: '정산예정',
			//         value: 'ready',
			//     },
			//     {
			//         text: '정산불능',
			//         value: 'fail',
			//     },
			// ],
			// onFilter: (value, record) => {
			//     const settleStatus = record?.is_settlement;
			//     if (record.order_detail_status === "cancel_request" || record.order_detail_status === "cancel_complete") {
			//         if (value === "fail") {
			//             return true;
			//         }
			//     } else {
			//         if (settleStatus && value === "success") {
			//             return true;
			//         } else {
			//             if (settleStatus === null && value === "ready") {
			//                 return true;
			//             } else {
			//                 if (value === "fail") return false;
			//             }
			//         }
			//     }
			// },
			render: (value, record) => {
				const settleStatus = value?.is_settlement;
				if (
					record.order_detail_status === "cancel_request" ||
					record.order_detail_status === "cancel_complete" ||
					record.order_detail_status === "return_request" ||
					record.order_detail_status === "return_receive" ||
					record.order_detail_status === "return_refusal" ||
					record.order_detail_status === "return_collection_complete" ||
					record.order_detail_status === "return_collection_fail" ||
					record.order_detail_status === "return_delivery" ||
					record.order_detail_status === "return_delivery_complete" ||
					record.order_detail_status === "return_complete"
				) {
					return <span style={{ color: "#ADB5BD" }}>정산불능</span>;
				} else {
					if (settleStatus) {
						return <span style={{ color: "#117ffa" }}>정산완료</span>;
					} else {
						if (settleStatus === null) {
							return <span>정산예정</span>;
						} else {
							return <span style={{ color: "#ADB5BD" }}>정산불능</span>;
						}
					}
				}
			},
		},
	];
	const clickColumns = [
		{
			title: "브랜드명",
			dataIndex: "product_brand_name",
			key: "product_brand_name",
			// width: 100,
		},
		{
			title: "상품명",
			// width: 170,
			dataIndex: "product_name",
			key: "product_name",
			render: (value) => {
				return (
					<span
						style={{
							width: "100%",
							textAlign: "center",
							display: "block",
							whiteSpace: "nowrap",
							overflow: "hidden",
							// maxWidth: 170,
							textOverflow: "ellipsis",
						}}
					>
						{value}
					</span>
				);
			},
		},
		{
			title: "클릭 수",
			// width: 150,
			dataIndex: "click_count",
			key: "click_count",
			render: (value) => {
				return value.toLocaleString();
			},
		},
		{
			title: "획득 골드(G)",
			dataIndex: "",
			key: "",
			render: (value, record) => {
				return (record.click_count * 100).toLocaleString();
			},
		},
	];
	const suggestColumns = [
		{
			title: "나를 추천한 아이디",
			dataIndex: "created_by_name",
			key: "created_by_name",
		},
		{
			title: "친구추천 수",
			dataIndex: "count",
			key: "count",
			render: (value) => {
				return value.toLocaleString();
			},
		},
		{
			title: "획득 골드(G)",
			dataIndex: "total_earn_gold",
			key: "total_earn_gold",
			render: (value) => {
				return value.toLocaleString();
			},
		},
	];
	const changeColumns = [
		{
			title: "정산 신청일",
			dataIndex: "request_date",
			key: "request_date",
			width: 200,
			render: (value) => moment(value).format("YYYY.MM.DD HH:mm"),
		},
		{
			title: "정산일",
			dataIndex: "complete_date",
			key: "complete_date",
			width: 200,
			render: (value) => {
				if (value) {
					return moment(value).format("YYYY.MM.DD HH:mm");
				} else return "-";
			},
		},
		{
			title: "정산 요청 골드(G)",
			dataIndex: "",
			key: "",
			render: (_, record) => {
				return `${(record.settlement_amount * 10).toLocaleString()}G`;
			},
		},
		{
			title: "정산 금액(원)",
			dataIndex: "settlement_amount",
			key: "settlement_amount",
			render: (value) => {
				return `${value.toLocaleString()}원`;
			},
		},
		{
			title: "정산 현황",
			dataIndex: "settlement_status",
			key: "settlement_status",
			render: (value) => {
				if (value === 0) {
					return "정산예정";
				} else if (value === 1) {
					return "정산완료";
				} else {
					return "정산불능";
				}
			},
		},
	];
	const [clickSummaryProducts, setClickSummaryProducts] = useState([]);
	const [recommendSummaryList, setRecommendSummaryList] = useState([]);
	const [settlementList, setSettlementList] = useState([]);

	useEffect(() => {
		// 분류선택 탭 변경 시 초기화
		setPageSize(10);
		setOffset(0);
	}, [groupType]);

	useEffect(() => {
		getStatisticsStatus();
		getOrderList("initial");
	}, []);

	useEffect(() => {
		getOrderList();
	}, [orderType, orderBy]);

	useEffect(() => {
		if (navClicked === "1") {
			getStatisticsStatus("initial");
			getOrderList("initial");
		} else {
			getGoldSettlementList();
		}
	}, [navClicked]);

	useEffect(() => {
		setOrderList(orderTotalList.slice(offset, offset + pageSize));
	}, [offset]);

	useEffect(() => {
		setOffset(0);
		setCurrentPageNumber(1);
		if (navClicked === "2") {
			getGoldSettlementList();
		} else {
			if (groupType === "sale") {
				getOrderList("initial");
			} else if (groupType === "click") {
				getClickSummaryProducts();
			} else if (groupType === "suggest");
		}
	}, [pageSize]);

	const getOrderList = async (type = "") => {
		setIsLoading(true);
		try {
			const linkParam = {
				userId: user?.id,
			};
			const linkRes = await api.getProductLinkList(linkParam);
			if (
				linkRes.data.code === 200 &&
				linkRes.data.result &&
				linkRes.data.result.length > 0
			) {
				const linkList = linkRes.data.result.map((link) => link.query);
				const query = linkList.join(",");
				const param = {
					startDate:
						type === "initial"
							? moment().startOf("day").format("YYYYMMDDHHmm")
							: searchOptions.startDate
								? searchOptions.startDate.startOf("day").format("YYYYMMDDHHmm")
								: moment()
										.subtract(1, "months")
										.startOf("day")
										.format("YYYYMMDDHHmm"),
					endDate:
						type === "initial"
							? moment().endOf("day").format("YYYYMMDDHHmm")
							: searchOptions.endDate
								? searchOptions.endDate.endOf("day").format("YYYYMMDDHHmm")
								: moment().endOf("day").format("YYYYMMDDHHmm"),
					queryList: query,
					orderType: orderType,
					orderBy: orderBy,
				};
				const res = await orderApi.orderByInfluencer(param);
				if (res.code === 200) {
					if (res.result.userOrderDetailDTOList.length > 1) {
						if (orderType === "created_at") {
							if (orderBy === "desc") {
								res.result.userOrderDetailDTOList.sort(
									(a, b) => moment(b.created_at) - moment(a.created_at)
								);
							} else {
								res.result.userOrderDetailDTOList.sort(
									(a, b) => moment(a.created_at) - moment(b.created_at)
								);
							}
						}
					}
					// let filterList = res.result.userOrderDetailDTOList.filter((item) => item.seller_id === 25).filter((item) => item.order_detail_status === "complete");
					// let filterTotal = 0;
					// filterList.map((item) => {
					//
					//     console.log(item);
					// })
					setOrderTotalList(res.result.userOrderDetailDTOList);
					if (type === "initial" || type === "search") {
						setOrderList(res.result.userOrderDetailDTOList.slice(0, pageSize));
					} else {
						setOrderList(
							res.result.userOrderDetailDTOList.slice(offset, offset + pageSize)
						);
					}
					if (res.result.length !== 0) {
						setTotalSize(res.result.totalCnt);
					} else {
						setTotalSize(0);
					}
					setUpdateDate(moment());
				} else {
					throw res.msg;
				}
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toastAlert(error);
		}
	};

	// 판매 리스트 조회
	const getStatisticsStatus = async (type = "") => {
		// if (user.query) {
		// setIsLoading(true)
		try {
			// let queryForAdmin = ""
			// if (user.query === "admin") {
			//     const queryListRes = await partnersApi.getQueryList()
			//     if (queryListRes.code === 200) {
			//         queryForAdmin = queryListRes.result.join(",")
			//     }
			// }
			const linkParam = {
				userId: user?.id,
			};
			const linkRes = await api.getProductLinkList(linkParam);
			if (
				linkRes.data.code === 200 &&
				linkRes.data.result &&
				linkRes.data.result.length > 0
			) {
				const linkList = linkRes.data.result.map((link) => link.query);
				const query = linkList.join(",");
				const queryLogParam = {
					startDate:
						type === "initial"
							? moment().startOf("day").format("YYYYMMDDHHmm")
							: searchOptions.startDate
								? searchOptions.startDate.startOf("day").format("YYYYMMDDHHmm")
								: moment()
										.subtract(1, "months")
										.startOf("day")
										.format("YYYYMMDDHHmm"),
					endDate:
						type === "initial"
							? moment().endOf("day").format("YYYYMMDDHHmm")
							: searchOptions.endDate
								? searchOptions.endDate.endOf("day").format("YYYYMMDDHHmm")
								: moment().endOf("day").format("YYYYMMDDHHmm"),
					query: query,
				};
				const param = {
					startDate:
						type === "initial"
							? moment().startOf("day").format("YYYYMMDDHHmm")
							: searchOptions.startDate
								? searchOptions.startDate.startOf("day").format("YYYYMMDDHHmm")
								: moment()
										.subtract(1, "months")
										.startOf("day")
										.format("YYYYMMDDHHmm"),
					endDate:
						type === "initial"
							? moment().endOf("day").format("YYYYMMDDHHmm")
							: searchOptions.endDate
								? searchOptions.endDate.endOf("day").format("YYYYMMDDHHmm")
								: moment().endOf("day").format("YYYYMMDDHHmm"),
					queryList: query,
				};
				const queryLogRes =
					await queryLogApi.queryLogByInfluencer(queryLogParam);
				const res = await orderApi.orderStatistics(param);
				if (queryLogRes.code === 200 && res.code === 200) {
					setStatistics({
						click: queryLogRes.result,
						purchases_num: res.result.purchases_num ?? 0,
						purchases_complete_num: res.result.purchases_complete_num ?? 0,
						cvr:
							queryLogRes.result &&
							queryLogRes.result !== 0 &&
							res.result.purchases_complete_num
								? (
										(res.result.purchases_complete_num / queryLogRes.result) *
										100
									).toFixed(2)
								: 0.0,
						combined_amount: res.result.combined_amount ?? 0,
						expected_profit_amount:
							(res.result.expected_profit_amount ?? 0) * 10,
						profit_amount: res.result.profit_amount ?? 0,
						total_price: res.result.total_purchase_amount ?? 0,
						click_count: 0,
						click_profit: 0,
					});
				} else if (queryLogParam.code !== 200) {
					throw queryLogParam.msg;
				} else {
					throw res.msg;
				}
			} else {
				setStatistics({
					click: 0,
					purchases_num: 0,
					purchases_complete_num: 0,
					cvr: 0.0,
					combined_amount: 0,
					expected_profit_amount: 0,
					profit_amount: 0,
					click_count: 0,
					click_profit: 0,
				});
			}

			// setIsLoading(false)
		} catch (error) {
			// setIsLoading(false)
			toastAlert(error);
		}
	};

	const handleSearch = () => {
		setOffset(0);
		setCurrentPageNumber(1);
		if (searchOptions.startDate === null || searchOptions.endDate === null) {
			toastAlert("조회하려는 기간이 없습니다. 다시 설정해주시길 바랍니다.");
			return false;
		} else {
			if (!searchOptions.startDate.isBefore(searchOptions.endDate)) {
				toastAlert(
					"조회하려는 기간이 잘못 설정되어있습니다. 다시 시도해주시길 바랍니다."
				);
				return false;
			} else {
				if (navClicked === "2") {
					getGoldSettlementList();
				} else {
					if (groupType === "sale") {
						getStatisticsStatus();
						getOrderList("search");
					} else if (groupType === "click") {
						getClickSummaryStatistics();
						getClickSummaryProducts();
					} else if (groupType === "suggest") {
						getRecommendSummaryStatistics();
						getRecommendSummary();
					}
				}
				return true;
			}
		}
	};

	const handleCancelBtnClick = () => {
		setSearchOptions({
			startDate: moment().startOf("day"),
			endDate: moment().endOf("day"),
		});
		setOffset(0);
		getStatisticsStatus("initial");
		getOrderList("initial");
	};

	const handelTableChange = (pagination, filters, sorter) => {
		if (sorter) {
			//sorting 이벤트가 이루어졌을때
			if (sorter.order) {
				if (sorter.order === "descend") {
					setOrderBy("desc");
				} else if (sorter.order === "ascend") {
					setOrderBy("asc");
				}
				setOrderType(sorter.field);
				//sorting 이벤트 데이터가 초기화 되었을 시 (order 값 초기화)
			} else {
				setOrderType("created_at");
				setOrderBy("desc");
			}
		}
	};

	/**
	 * page 넘김
	 */
	const handleChangePagination = (pageNumber) => {
		const currOffset = (pageNumber - 1) * pageSize;
		setCurrentPageNumber(pageNumber);
		setOffset(currOffset);
	};

	useEffect(() => {
		if (detail) {
			getStatisticsStatus();
			getOrderList("search");
			setDetail(false);
		}
	}, [searchOptions, detail]);

	/**
	 * 자세히 버튼 클릭
	 * @param e
	 * @param value
	 */
	const settlementChange = (e, value) => {
		const date = value.settlement_month;
		const settleDate = moment(date).subtract(1, "month").format("YYYY.MM.DD");
		setSearchOptions({
			startDate: moment(settleDate).startOf("month"),
			endDate: moment(settleDate).endOf("month"),
		});
		setNavClicked("2");
		setDetail(true);
	};

	const dropdownItems = (
		<Menu>
			<Menu.Item style={{ fontSize: "0.9em" }} onClick={() => setPageSize(10)}>
				10개씩 보기
			</Menu.Item>
			<Menu.Item style={{ fontSize: "0.9em" }} onClick={() => setPageSize(20)}>
				20개씩 보기
			</Menu.Item>
			<Menu.Item style={{ fontSize: "0.9em" }} onClick={() => setPageSize(30)}>
				30개씩 보기
			</Menu.Item>
		</Menu>
	);

	const renderEmpty = () => (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "20em",
			}}
		>
			{/* <img
				src={`/assets/images/report/empty-data.png`}
				alt={`채널 이미지`}
				style={{ height: 70, width: 70 }}
			/> */}
			<span
				style={{
					color: "#D9D9D9",
					fontSize: "1.3em",
					fontWeight: "600",
					height: "100%",
					display: "flex",
					alignItems: "center",
				}}
			>
				리포트가 비었습니다.
			</span>
		</div>
	);

	const handleExcelDown = () => {
		console.log("handleExcelDown");
		let excelData = [];
		excelData = orderTotalList?.map((row) => {
			// let commission_dy = row.is_cooperate ? 0 : Math.floor(row.total_price * 0.11);
			// let commission_sell = row.is_cooperate
			//     ? Math.floor(row.total_price * ((row.cooperate_fee_rate * 1.1) / 100)) :
			//     row.is_influencer
			//         ? Math.floor(row.total_price * (row.influencer_fee_rate / 100)) : 0
			// let profit_price = row.total_price
			// if (row.is_cooperate) {
			//     profit_price = profit_price - Math.floor(row.total_price * ((row.cooperate_fee_rate * 1.1) / 100))
			// } else {
			//     if (row.is_influencer) {
			//         profit_price = profit_price - Math.floor(row.total_price * (row.influencer_fee_rate / 100))
			//     }
			//     profit_price = profit_price - Math.floor(row.total_price * 0.11)
			// }

			let commissionStatus = "";
			if (
				row.order_detail_status === "cancel_request" ||
				row.order_detail_status === "cancel_complete" ||
				row.order_detail_status === "return_request" ||
				row.order_detail_status === "return_receive" ||
				row.order_detail_status === "return_refusal" ||
				row.order_detail_status === "return_collection_complete" ||
				row.order_detail_status === "return_collection_fail" ||
				row.order_detail_status === "return_delivery" ||
				row.order_detail_status === "return_delivery_complete" ||
				row.order_detail_status === "return_complete"
			) {
				commissionStatus = "정산불능";
			} else {
				if (row?.is_settlement) {
					commissionStatus = "정산완료";
				} else {
					if (row?.is_settlement === null) {
						commissionStatus = "정산예정";
					} else {
						commissionStatus = "정산불능";
					}
				}
			}
			// let vat_title = row.vat ? "corporation_vat" : "individual_tax";
			// let profit = row.total_price * (row.commission_rate / 100);
			// let vat_price = 0;
			// let profit_price = 0;
			// if (row.vat) {
			// vat_price = Math.round(profit * 0.1);
			// profit_price = Math.round(profit * 1.1);
			// } else {
			// vat_price = Math.round(profit * 0.033);
			// profit_price = Math.round(profit * 0.967);
			// }
			return {
				// order_id: row.order_id,
				// order_detail_id: `dy${row.order_detail_id}`,
				brand_name: row.product_brand_name,
				// product_id: row.product_id,
				// option_id: row.option_id,
				product_name: row.product_name,
				option_title: row.option_title,
				created_at: moment(row.created_at).format("YYYY.MM.DD HH:mm"),
				complete_date: row.complete_date
					? moment(row.complete_date).format("YYYY.MM.DD HH:mm")
					: "",
				// name: row.name,
				order_detail_status:
					status.matchingOrderStatus[row.order_detail_status],
				total_price: row.total_price,
				commission_rate: row.commission_rate + "%",
				profit:
					commissionStatus === "정산불능"
						? 0
						: Math.round(row.total_price * (row.commission_rate / 100)),
				// [vat_title]: vat_price,
				// profit_price: profit_price,
				status: commissionStatus,
			};
		});
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(excelData);
		Object.keys(excelData[0]).forEach((key, idx) => {
			const cellAdd = XLSX.utils.encode_cell({ c: idx, r: 0 });
			ws[cellAdd].v = status.matchingKey[key];
		});
		XLSX.utils.book_append_sheet(wb, ws, "정산상태");
		// XLSX.writeFile(wb, `${moment().format("YYYYMMDD")}_정산상태.xlsx`)
		XLSX.writeFile(
			wb,
			`${searchOptions.startDate.format(
				"YYMMDD"
			)}-${searchOptions.endDate.format("YYMMDD")}_정산상태.xlsx`
		);
	};

	// 클릭 통계
	const getClickSummaryStatistics = async () => {
		try {
			const param = {
				userId: user?.id,
				startDate: moment(searchOptions.startDate).format("YYYYMMDD"),
				endDate: moment(searchOptions.endDate).format("YYYYMMDD"),
			};

			const res = await api.getClickSummaryStatistics(param);

			if (res.data.code === 200) {
				setStatistics({
					...statistics,
					click_count: res.data.result.click_count,
					click_profit: res.data.result.click_profit,
				});
			} else {
				throw res.msg;
			}
		} catch (error) {
			toastAlert(error);
		}
	};

	useEffect(() => {
		handleSearch();
	}, [groupType]);

	// 클릭 상품 리스트
	const getClickSummaryProducts = async () => {
		setIsLoading(true);
		try {
			const param = {
				offset: offset,
				pageSize: pageSize,
				userId: user?.id,
				startDate: moment(searchOptions.startDate).format("YYYYMMDD"),
				endDate: moment(searchOptions.endDate).format("YYYYMMDD"),
			};
			const res = await api.getClickSummaryProducts(param);

			if (res.data.code === 200) {
				setTotalSize(res.data.result.totalCnt);
				setClickSummaryProducts(res.data.result.clickSummaryListForProduct);
			} else {
				throw res.msg;
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toastAlert(error);
		}
	};

	/**
	 * 친구추천 통계 조회
	 * @returns {Promise<void>}
	 */
	const getRecommendSummaryStatistics = async () => {
		try {
			const param = {
				type: "period",
				userId: user?.id,
				startDate: moment(searchOptions.startDate).format("YYYYMMDD"),
				endDate: moment(searchOptions.endDate).format("YYYYMMDD"),
			};

			const res = await api.getRecommendSummaryStatistics(param);

			if (res.data.code === 200) {
				setStatistics({
					...statistics,
					recommend_count: res.data.result.count,
					recommend_profit: res.data.result.total_earn_gold,
				});
			} else {
				throw res.msg;
			}
		} catch (error) {
			toastAlert(error);
		}
	};

	/**
	 * 친구추천 내역 조회
	 * @returns {Promise<void>}
	 */
	const getRecommendSummary = async () => {
		setIsLoading(true);
		try {
			const param = {
				offset: offset,
				pageSize: pageSize,
				userId: user?.id,
				startDate: moment(searchOptions.startDate).format("YYYYMMDD"),
				endDate: moment(searchOptions.endDate).format("YYYYMMDD"),
			};
			const res = await api.getRecommendSummary(param);

			if (res.data.code === 200) {
				setTotalSize(res.data.result.totalCnt);
				setRecommendSummaryList(res.data.result.recommendSummaryList);
			} else {
				throw res.msg;
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toastAlert(error);
		}
	};

	/**
	 * 골드 정산 내역 조회
	 * @returns {Promise<void>}
	 */
	const getGoldSettlementList = async () => {
		setIsLoading(true);
		try {
			const param = {
				offset: offset,
				pageSize: pageSize,
				userId: user?.id,
				startDate: moment(searchOptions.startDate).format("YYYYMMDD"),
				endDate: moment(searchOptions.endDate).format("YYYYMMDD"),
			};
			const res = await api.getGoldSettlementList(param);
			if (res.data.code === 200) {
				setTotalSize(res.data.result.totalCnt);
				setSettlementList(res.data.result.settlementList);
			} else {
				throw res.msg;
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toastAlert(error);
		}
	};

	useEffect(() => {
		if (currentPageNumber === 1) return;
		if (navClicked === "2") {
			getGoldSettlementList();
		} else {
			if (groupType === "click") {
				getClickSummaryProducts();
			} else if (groupType === "suggest") {
				getRecommendSummary();
			}
		}
	}, [currentPageNumber]);

	return (
		<div className="main-wrap">
			<div className="max-width-apply">
				{!mediaResult && <LinkComponent />}
				<h1 className="main-title add-mobile-css">리포트</h1>

				<div className={`main-nav add-mobile-css`}>
					<button
						className={`${navClicked === "1" ? "clicked" : ""}`}
						onClick={() => {
							history.push(`/reports`);
							if (navClicked !== "1") {
								setSearchOptions({
									startDate: moment().startOf("day"),
									endDate: moment().endOf("day"),
								});
								setOffset(0);
							}
							setNavClicked("1");
						}}
					>
						수익
					</button>
					<button
						className={`${navClicked === "2" ? "clicked" : ""}`}
						onClick={() => {
							setSearchOptions({
								startDate: moment().startOf("day"),
								endDate: moment().endOf("day"),
							});
							setOffset(0);
							setDateType("today");
							setNavClicked("2");
						}}
					>
						정산
					</button>
				</div>
				<ReportDateSearchComponent
					searchOptions={searchOptions}
					setSearchOptions={setSearchOptions}
					updateDate={updateDate}
					handleSearch={handleSearch}
					// handleCancelBtnClick={handleCancelBtnClick}
					setGroupType={setGroupType}
					navClicked={navClicked}
					dateType={dateType}
					groupType={groupType}
					setDateType={setDateType}
					mediaResult={mediaResult}
				/>
				{navClicked === "1" && (
					<ReportStatisticsComponent
						statistics={statistics}
						orderList={orderList}
						groupType={groupType}
						mediaResult={mediaResult}
					/>
				)}
				{/* 테이블 */}
				{!(navClicked === "1" && groupType === "click") && (
					<>
						<Row
							justify={"space-between"}
							align={"middle"}
							style={{ padding: "2em 0 1em" }}
						>
							<p
								style={{
									margin: 0,
									fontWeight: mediaResult ? 500 : "bold",
									fontSize: mediaResult ? "1.1em" : "0.9em",
									lineHeight: mediaResult ? 1.2 : 1.5,
									color: "#1A1A1A",
								}}
							>
								{!mediaResult && "목록 "}총 {totalSize}개
							</p>
							<Row align={"middle"} style={{ gap: "5px" }}>
								{!mediaResult && (
									<Dropdown overlay={dropdownItems} trigger={"click"}>
										<Button
											size={`small`}
											style={{
												fontSize: "0.9em",
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
												width: "10em",
											}}
										>
											{pageSize}개씩 보기
											<img
												src={`/assets/images/report/dropdown.png`}
												alt={`드롭다운`}
												style={{
													height: "auto",
													width: "0.8em",
												}}
											/>
										</Button>
									</Dropdown>
								)}
								{/* <Button
									size={`small`}
									onClick={handleExcelDown}
									style={{ fontSize: "0.9em" }}
								>
									<img
										src={`/assets/images/excel-icon.png`}
										alt={`화살표`}
										style={{
											height: "1em",
											width: "auto",
											marginRight: "5px",
										}}
									/>
									엑셀다운
								</Button> */}
							</Row>
						</Row>
						<ConfigProvider renderEmpty={renderEmpty}>
							<Table
								className="report-table-wrap"
								columns={
									navClicked === "1"
										? groupType === "sale"
											? saleColumns
											: groupType === "click"
												? clickColumns
												: suggestColumns
										: changeColumns
								}
								dataSource={
									navClicked === "2"
										? settlementList
										: groupType === "sale"
											? orderList
											: groupType === "click"
												? clickSummaryProducts
												: recommendSummaryList
								}
								loading={isLoading}
								rowKey="order_detail_id"
								scroll={{ x: "max-content" }}
								tableLayout="auto"
								footer={() => <></>}
								onChange={handelTableChange}
								showSorterTooltip={false}
								pagination={false}
								size={`small`}
								style={{
									margin: 0,
								}}
							/>
						</ConfigProvider>
						<Pagination
							className="report-pagination-wrap"
							showSizeChanger={false}
							current={currentPageNumber}
							pageSize={pageSize}
							total={totalSize}
							onChange={handleChangePagination}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default Reports;
