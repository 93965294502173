import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Dropdown, Row } from "antd";

const HeaderComponent = () => {
	const history = useHistory();
	const location = useLocation();
	const path = location.pathname;

	const user = useSelector((state) => state.auth.user);

	const items = [
		// {
		// 	label: (
		// 		<Link
		// 			to={`/dashboard`}
		// 			style={
		// 				path.includes("/dashboard")
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			홈
		// 		</Link>
		// 	),
		// 	key: "dashboard",
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={`/products`}
		// 			style={
		// 				path === "/products"
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			상품 링크
		// 		</Link>
		// 	),
		// 	key: "products",
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={`/reports`}
		// 			style={
		// 				path === "/reports"
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			리포트
		// 		</Link>
		// 	),
		// 	key: "reports",
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={`/faq`}
		// 			style={
		// 				path === "/faq"
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			자주묻는 질문
		// 		</Link>
		// 	),
		// 	key: "faq",
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={`/notice`}
		// 			style={
		// 				path.includes("/notice")
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			공지사항
		// 		</Link>
		// 	),
		// 	key: "notice",
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={`/benefit/information`}
		// 			style={
		// 				path === "/benefit/information"
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			이용가이드
		// 		</Link>
		// 	),
		// 	key: "information",
		// },
	];

	const itemsGuest = [
		// {
		// 	label: (
		// 		<Link
		// 			to={"/faq"}
		// 			style={
		// 				path.includes("/faq")
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			자주묻는 질문
		// 		</Link>
		// 	),
		// 	key: 1,
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={"/notice"}
		// 			style={
		// 				path.includes("/notice")
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			공지사항
		// 		</Link>
		// 	),
		// 	key: 2,
		// },
		// {
		// 	label: (
		// 		<Link
		// 			to={"/benefit/information"}
		// 			style={
		// 				path.includes("/benefit/information")
		// 					? {
		// 							color: "#117ffa",
		// 							fontWeight: "bold",
		// 					  }
		// 					: {}
		// 			}
		// 		>
		// 			이용가이드
		// 		</Link>
		// 	),
		// 	key: 3,
		// },
	];

	return (
		<Row
			justify="space-between"
			align="middle"
			wrap={false}
			className="together-header-wrap noselect"
		>
			{/*헤더 왼쪽 메뉴*/}
			{user ? (
				<div className={`header-link-div`}>
					{/* 모바일 햄버그 드롭다운 */}
					{/*<Dropdown*/}
					{/*	menu={{ items }}*/}
					{/*	placement="bottomLeft"*/}
					{/*	arrow={true}*/}
					{/*	trigger={["click"]}*/}
					{/*	className={`menu-dropdown`}*/}
					{/*>*/}
					{/*	<img*/}
					{/*		src={`/assets/images/hamburger-icon.png`}*/}
					{/*		alt={`햄버그 아이콘`}*/}
					{/*		style={{*/}
					{/*			width: "1.5em",*/}
					{/*			height: "auto",*/}
					{/*			cursor: "pointer",*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</Dropdown>*/}
					{/* 로고 */}
					<Link to={`/dashboard`}>
						<img
							src={`/assets/images/main/togetherLogo.png`}
							className="together-header-logo"
							alt={`당영 투게더 로고`}
						/>
					</Link>
					{/*<div className={`link-group`}>*/}
					{/*	<Link*/}
					{/*		to={`/dashboard`}*/}
					{/*		style={*/}
					{/*			path.includes("/dashboard")*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		홈*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/products`}*/}
					{/*		style={*/}
					{/*			path === "/products"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		상품 링크*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/reports`}*/}
					{/*		style={*/}
					{/*			path === "/reports"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		리포트*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/faq`}*/}
					{/*		style={*/}
					{/*			path === "/faq"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		자주묻는 질문*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/notice`}*/}
					{/*		style={*/}
					{/*			path.includes("/notice")*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		공지사항*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/benefit/information`}*/}
					{/*		style={*/}
					{/*			path === "/benefit/information"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		이용가이드*/}
					{/*	</Link>*/}
					{/*</div>*/}
				</div>
			) : (
				// 비회원
				<div className={`header-link-div`}>
					{/*<Dropdown*/}
					{/*	menu={{ items: itemsGuest }}*/}
					{/*	placement="bottomLeft"*/}
					{/*	arrow={true}*/}
					{/*	trigger={["click"]}*/}
					{/*	className={`menu-dropdown`}*/}
					{/*>*/}
					{/*	<img*/}
					{/*		src={`/assets/images/hamburger-icon.png`}*/}
					{/*		alt={`햄버그 아이콘`}*/}
					{/*		style={{*/}
					{/*			width: "1.5em",*/}
					{/*			height: "auto",*/}
					{/*			cursor: "pointer",*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</Dropdown>*/}
					<Link to={`/`}>
						<img
							src={`/assets/images/main/togetherLogo.png`}
							className="together-header-logo"
							alt={`당영 투게더 로고`}
						/>
					</Link>
					{/*<div className={`link-group`}>*/}
					{/*	<Link*/}
					{/*		to={`/faq`}*/}
					{/*		style={*/}
					{/*			path === "/faq"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		자주묻는 질문*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/notice`}*/}
					{/*		style={*/}
					{/*			path === "/notice"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		공지사항*/}
					{/*	</Link>*/}
					{/*	<Link*/}
					{/*		to={`/benefit/information`}*/}
					{/*		style={*/}
					{/*			path === "/benefit/information"*/}
					{/*				? {*/}
					{/*						color: "#117ffa",*/}
					{/*						fontWeight: "bold",*/}
					{/*				  }*/}
					{/*				: {}*/}
					{/*		}*/}
					{/*	>*/}
					{/*		이용가이드*/}
					{/*	</Link>*/}
					{/*</div>*/}
				</div>
			)}
			{/*헤더 오른쪽 메뉴*/}
			{user ? (
				<div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
					<UserGradeIconNickname user={user} />
				</div>
			) : (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						height: "5em",
					}}
					className="header-right-mobile"
				>
					{/* <button
						style={{
							display: "flex",
							height: "100%",
							alignItems: "center",
						}}
						onClick={() => history.push(`/`)}
					>
						로그인
					</button>
					<button
						style={{
							display: "flex",
							height: "100%",
							alignItems: "center",
						}}
						onClick={() => history.push(`/user/signup`)}
					>
						회원가입
					</button> */}
				</div>
			)}
		</Row>
	);
};

const UserGradeIconNickname = ({ user }) => {
	const history = useHistory();
	const gradeIcon =
		user?.grade === "grade_3"
			? "seller_3"
			: user?.grade === "grade_2"
				? "seller_2"
				: "seller_1";
	return (
		<>
			{/* --- pc (이름 보임)--- */}
			<div className={`user-grade-icon-nickname`}>
				<button
					onClick={() => history.push(`/user`)}
					style={{ display: "flex", alignItems: "center" }}
				>
					{/*<div*/}
					{/*	style={{*/}
					{/*		width: "1.8em",*/}
					{/*		height: "1.8em",*/}
					{/*		marginRight: "0.375rem",*/}
					{/*		display: "flex",*/}
					{/*		alignItems: "center",*/}
					{/*		justifyContent: "center",*/}
					{/*		overflow: "hidden",*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<img*/}
					{/*		src={`/assets/images/seller/${gradeIcon}.png`}*/}
					{/*		alt={`유저 아이콘`}*/}
					{/*		style={{*/}
					{/*			width: "80%",*/}
					{/*			height: "80%",*/}
					{/*			objectFit: "cover",*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</div>*/}
					<p style={{ color: "#000", fontWeight: "500" }}>{user.name}</p>
					<div
						style={{
							width: "0.8rem",
							height: "0.6rem",
							marginLeft: "0.5rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							overflow: "hidden",
						}}
					>
						<img
							src={`/assets/images/triangle-btn-icon.png`}
							alt={`삼각형 버튼`}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					</div>
				</button>
			</div>
			{/* --- 모바일 (아이콘 보임)--- */}
			<div className={`user-grade-icon-nickname icon`}>
				<button
					onClick={() => history.push(`/user`)}
					style={{ display: "flex", alignItems: "center", background: "none" }}
				>
					<div
						style={{
							width: "2.3rem",
							height: "2.3rem",
							marginLeft: "0.5rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							overflow: "hidden",
						}}
					>
						<img
							src={`/assets/images/ico-mypage.svg`}
							alt={`삼각형 버튼`}
							style={{
								width: "100%",
								height: "100%",
							}}
						/>
					</div>
				</button>
			</div>
		</>
	);
};

export default HeaderComponent;
