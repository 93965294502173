import "../Event.scss";
import { useEffect, useState } from "react";

const BenefitInformation = () => {
	const [mediaResult, setMediaResult] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const handleChange = (e) => {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", handleChange);
		}
	}, []);

	return (
		<div className="main-wrap event-banner-page">
			{/*배너*/}
			<div className={`banner-wrap using-guide`}>
				<img
					src={`/assets/images/event/user-guide-banner${
						mediaResult ? "-m" : ""
					}.jpg`}
					alt={`user_guide_banner`}
					style={{
						width: "100%",
						height: "auto",
					}}
				/>
				<div className={`banner-title-wrap user-guide`}>
					<h1 className={`banner-title`}>
						누구나 쉽게 시작할 수 있는{"\n"}
						<span>당영투게더</span>
					</h1>
				</div>
			</div>
			{/*내용*/}
			<div className={`content-wrap`}>
				{/*내용 메인 텍스트*/}
				<div className={`content-title-wrap`}>
					<p className={`content-title-category`}>Way</p>
					<h2 className={`content-title user-guide-page`}>
						당영투게더 시작?!{"\n"}
						<span>아주 쉽고 간단합니다!!</span>
					</h2>
				</div>
				{/*내용 목록*/}
				<div className={`content-list-wrap`}>
					{/*01 링크 생성*/}
					<div className={`content-list-item`}>
						<div className={`content-list-item-category`}>01 링크 생성</div>
						<p
							className={`content-list-item-description`}
							style={
								mediaResult
									? { marginLeft: "0.5em", marginRight: "0.5em" }
									: {
											marginLeft: "1em",
											marginRight: "1em",
										}
							}
						>
							{mediaResult ? (
								<>
									상품과 수수료율 확인 후 원하는 <span>상품의 링크를 생성</span>
									해주세요
								</>
							) : (
								<>
									상품과 수수료율 확인 후 원하는 상품의{" "}
									<span>링크 생성 클릭</span> 후 링크생성 팝업창이 뜨면{" "}
									<span>링크 복사하기를 클릭</span>
									해주세요
								</>
							)}
						</p>
						<img
							className={`content-list-item-description-img`}
							src={`/assets/images/event/benefit-list-item-1${
								mediaResult ? "-m" : ""
							}.png`}
							alt={`benefit-list-item-1`}
							style={{
								width: "100%",
								height: "auto",
							}}
						/>
					</div>
					{/*02 링크 공유*/}
					<div className={`content-list-item`}>
						<div className={`content-list-item-category`}>02 링크 공유</div>
						<p className={`content-list-item-description hor-padding-plus-m`}>
							블로그 및 SNS, 지인들에게 생성한 링크를 공유해주세요!
						</p>
						<img
							className={`content-list-item-description-img`}
							src={`/assets/images/event/benefit-list-item-2${
								mediaResult ? "-m" : ""
							}.png`}
							alt={`benefit-list-item-2`}
							style={{
								width: "100%",
								height: "auto",
							}}
						/>
						{!mediaResult && (
							<p
								className={`content-list-item-description-img-text add-padding-top-1`}
							>
								네이버 블로그
							</p>
						)}
						<p className={`content-list-item-description-img-text`}>
							예시 블로그 : https://blog.naver.com/khanvikal
						</p>
						<p className={`content-list-item-description ver-padding`}>
							꼭 블로그가 아니어도 됩니다.{mediaResult ? `\n` : " "}인스타나
							유튜브 등 다양한 SNS에 링크를 활용하고, 메신저로 주변에
							추천해보세요.
						</p>
						<p className={`content-list-item-description hor-padding`}>
							내가 직접 먹기 위해 구매하거나 지인들이 구매를 하여도 최대 30%
							수수료가 적립됩니다!
						</p>
					</div>
					{/*03 정산 하기*/}
					<div className={`content-list-item item-3th`}>
						<div className={`content-list-item-category`}>03 정산 하기</div>
						<p className={`content-list-item-description hor-padding-plus-m`}>
							링크 클릭 및 판매를 통해 발생된 <span>수익을 확인하고, 정산</span>{" "}
							받으세요.
						</p>
					</div>
				</div>
				{/*여기서 잠깐!*/}
				<div className={`padding-div`}>
					<div className={`content-warning-text-wrap`}>
						<h3 className={`content-warning-title`}>여기서 잠깐!</h3>
						<p className={`content-warning-sub-title`}>
							당영투게더 활동 시, 링크가 삽입된 모든 게시물에{" "}
							<span>대가성 문구를 반드시 표기</span>해 주세요.
						</p>
						<div className={`content-warning-quote-wrap`}>
							<p className={`content-warning-quote`}>
								이 포스팅은 당영투게더 활동의 일환으로,{"\n"}이에 따른 일정액의
								수수료를 제공받습니다.
							</p>
						</div>
						<p className={`content-warning-help-text`}>
							위와 같은 대가성 멘트를 꼭 마지막에 기재해주세요!
						</p>
					</div>
				</div>
				{/*한번 더 확인해주세요!*/}
				<div className={`content-one-more-wrap`}>
					<h3 className={`title`}>
						<span>한번 더 확인</span>해주세요!
					</h3>
					<p className={`description`}>
						반드시 링크생성 버튼을 통해{"\n"}링크를 복사해야 링크를 통한 수익이
						적립되요!
					</p>
					<div className={`content-one-more-example-wrap`}>
						{mediaResult ? (
							<div className={`content-one-more-example-item`}>
								<img
									className={`content-list-item-description-img`}
									src={`/assets/images/event/user-guide-link.png`}
									alt={`user-guide-link`}
									style={{
										width: "100%",
										height: "auto",
									}}
								/>
								<div className={`content-list-item-description-text-wrap`}>
									<p className={`text`}>[ 링크생성 ]</p>
									<p className={`text`}>[ 링크복사 ]</p>
								</div>
							</div>
						) : (
							<>
								<div className={`content-one-more-example-item`}>
									<img
										className={`content-list-item-description-img`}
										src={`/assets/images/event/user-guide-link-create.png`}
										alt={`user-guide-link-create`}
										style={{
											width: "100%",
											height: "auto",
										}}
									/>
									<p className={`text`}>[ 링크생성 ]</p>
								</div>
								<div className={`content-one-more-example-item`}>
									<img
										className={`content-list-item-description-img`}
										src={`/assets/images/event/user-guide-link-copy.png`}
										alt={`user-guide-link-copy`}
										style={{
											width: "100%",
											height: "auto",
										}}
									/>
									<p className={`text`}>[ 링크복사 ]</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BenefitInformation;
