const ProductNav = ({
	handleCategory,
	category,
	setCategory,
	type,
	mediaResult,
}) => {
	const productCategoryList = [
		{ category_name: "전체", category_id: "all" },
		// { category_name: "BEST", category_id: "best" },
		// { category_name: "고수익", category_id: "profit" },
		{ category_name: "비타민", category_id: "vitamin" },
		{ category_name: "미네랄", category_id: "mineral" },
		{ category_name: "영양제", category_id: "nutrition" },
		{ category_name: "이너뷰티", category_id: "beauty" },
	];

	return (
		<div
			className={`main-nav product-nav ${
				type === "dashboard"
					? type
					: type === "products"
						? "add-mobile-css"
						: ""
			}`}
			style={{
				marginBottom: type === "products" && mediaResult ? 19 : "0.5em",
			}}
		>
			{productCategoryList.map((item, idx) => (
				<button
					className={item.category_id === category.category_id ? "clicked" : ""}
					onClick={() => {
						handleCategory(item);
						setCategory(item);
					}}
					key={`상품링크 카테고리 메뉴바 ${idx}`}
				>
					{item?.category_name}
				</button>
			))}
		</div>
	);
};

export default ProductNav;
