import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
	useRef,
} from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Dropdown, Input, Modal, Row } from "antd";

import Swal from "sweetalert2";

import partnersApi from "../../apis/Partners.api";
import Policy from "../../pages/common/Policy";
import Terms from "../../pages/common/Terms";
import { setChannelUser, setUser } from "../../redux/store/auth/authDuck";
import { setLoading } from "../../redux/store/common/commonDuck";
import BankListComponent from "../common/BankListComponent";
import PersonalAuthentication from "../common/PersonalAuthentication";

import SignUpHeader from "./SignUpHeader";

const TogetherSignup = () => {
	const mainRef = useRef(null);
	const user = useSelector((state) => state.auth.user);

	const [certifiedData, setCertifiedData] = useState({});

	const dispatch = useDispatch();
	const history = useHistory();
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [isShowModalCheckBankAccount, setIsShowModalCheckBankAccount] =
		useState(false);
	const [isShowModalSuccessBankAccount, setIsShowModalSuccessBankAccount] =
		useState(false);
	const [userValue, setUserValue] = useState({
		name: "",
		first_personal_number: "",
		second_personal_number: "",
		phone: "",
		email: "",
		accountName: "",
	});
	const [submitBank, setSubmitBank] = useState({
		bank_code: "",
		account_number: "",
		is_submit: false,
	}); // 계좌번호 인증
	const [submitBankLoading, setSubmitBankLoading] = useState(false);
	const [agreement, setAgreement] = useState({
		policy: false,
		terms: false,
	});
	const [selectedBank, setSelectedBank] = useState({
		code: null,
		name: null,
	}); // 선택한 은행
	const [bankList, setBankList] = useState([]);
	const [checkBankAccountNumber, setCheckBankAccountNumber] =
		useState(undefined);
	const [isShowPolicy, setIsShowPolicy] = useState(false);
	const [isShowTerms, setIsShowTerms] = useState(false);

	useLayoutEffect(() => {
		if (!user?.id) {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "잘못된 접근입니다.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then(() => {
				history.goBack();
			});
		}
	}, []);

	useEffect(() => {
		getBankList();
		window.scrollTo({ top: 0 });
	}, []);

	/**
	 * 회원 정보 받기
	 * @type {(function(*): void)|*}
	 */
	const handleInputUser = useCallback(
		(e) => {
			const { value, name } = e.target;

			// 주민번호 앞자리
			if (name === "first_personal_number") {
				if (value.split("-").join("").length <= 6) {
					let number = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						first_personal_number: number,
					});
				}
			}
			// 주민번호 뒷자리
			else if (name === "second_personal_number") {
				if (value.split("-").join("").length <= 7) {
					let number = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						second_personal_number: number,
					});
				}
			}
			// 휴대폰 번호
			else if (name === "phone") {
				if (value.split("-").join("").length <= 11) {
					let phone = value.replace(/[^0-9]/g, "");
					setUserValue({
						...userValue,
						phone: phone,
					});
				}
			} else {
				setUserValue({
					...userValue,
					[name]: value,
				});
			}
		},
		[userValue]
	);

	/**
	 * 계좌인증
	 * @param type account-입력한 계좌번호로 1원 전송하기 / code-1원 전송한 계좌 코드 입력하기
	 * @returns {Promise<void>}
	 */
	const verifyAccount = async (type = "account") => {
		try {
			setSubmitBankLoading(true);
			let param = {
				...submitBank,
				type: type,
				user_id: user?.id,
			};
			if (type === "account") {
				param["name"] = userValue.name;
				param["birthday"] = userValue.first_personal_number;
			} else {
				param["code"] = checkBankAccountNumber;
			}
			const res = await partnersApi.verifyAccount(param);
			if (res.data.code === 200) {
				if (type === "account") {
					setIsShowModalCheckBankAccount(true);
					setCheckBankAccountNumber(null);
				} else {
					setIsShowModalSuccessBankAccount(true);
					setCheckBankAccountNumber(undefined);
					// 계좌 소유자 본명으로 처리
					setUserValue({
						...userValue,
						accountName: userValue.name,
					});
					setSubmitBank({
						...submitBank,
						is_submit: true,
					});
				}
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `${res.data.msg}`,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
			}
		} catch (e) {
			console.log("verifyAccount :: e = ", e);
		} finally {
			setSubmitBankLoading(false);
		}
	};

	/**
	 * 가입하기 버튼 클릭
	 */
	const handleSignupButton = () => {
		// 유저 - 이름, 주민번호(앞/뒤), 휴대폰, 이메일
		// 계좌 - 은행코드, 계좌번호
		// 수집 동의 - 개인정보 처리방침, 고유식별정보 수집 및 이용
		let valid =
			userValue.name?.length > 0 &&
			userValue.first_personal_number?.length > 0 &&
			userValue.second_personal_number?.length > 0 &&
			userValue.phone?.length > 0 &&
			submitBank.bank_code?.length > 0 &&
			submitBank.account_number?.length > 0 &&
			submitBank.is_submit &&
			agreement.policy &&
			agreement.terms &&
			certifiedData.is_certified;

		if (valid) {
			setIsLoading(true);
			const timer = setTimeout(() => {
				updateUserInformation();
			}, 100);
			return () => clearTimeout(timer);
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "필수 입력 항목을 모두 적어주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			const timer = setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}, 100);
			return () => clearTimeout(timer);
		}
	};

	const updateUserInformation = async () => {
		let param = {
			id: user.id,
			key: "additional_info",
			name: userValue.name,
			email: userValue.email,
			phone: userValue.phone,
			personal_number:
				userValue.first_personal_number +
				"-" +
				userValue.second_personal_number,
			bank_code: submitBank.bank_code,
			account_number: submitBank.account_number,
			is_app: false,
			account_holder_name: userValue.accountName,
		};
		const res = await partnersApi.postPersonalUserData(param);
		if (res.code === 200) {
			dispatch(
				setUser({
					...user,
					// bank_code: res.result?.bank_code,
					// account_number: res.result?.account_number,
					nickname: res.result?.nickname,
					// phone: res.result?.phone,
					is_influencer: true,
					is_certified: true,
					// account_holder_name: res.result?.account_holder_name,
				})
			);

			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `투게더 가입을 환영합니다!`,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then(() => {
				history.replace("/dashboard");
			});
		} else if (res.code === 998) {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "입력하신 이름이나 연락처가\n인증된 정보와 일치하지 않습니다.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			}).then(() => {
				console.log("updateUserInformation");
			});
		}
		setIsLoading(false);
	};

	/**
	 * 로그아웃
	 * @returns {Promise<void>}
	 */
	const logout = async () => {
		setIsLoading(true);
		Swal.fire({
			customClass: "alert-custom-confirm",
			text: `정말 로그아웃 하시겠습니까?`,
			showCancelButton: true,
			confirmButtonText: "확인",
			confirmButtonColor: "#117FFA",
			cancelButtonText: "취소",
			cancelButtonColor: "#FFFFFF",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const param = {
					id: user.id,
				};
				await partnersApi.logout(param);
				localStorage.removeItem("admin:accessToken");
				localStorage.removeItem("admin:refreshToken");

				dispatch(setChannelUser(null));
				dispatch(setUser(null));
				setIsLoading(false);
				history.push(`/`);
			}
		});
		setIsLoading(false);
	};

	const items =
		bankList?.length > 0
			? bankList?.map((item) => ({
					label: item.name,
					key: item.code,
				}))
			: [];

	const onClick = ({ key }) => {
		let clickBank = bankList.filter((item) => item.code === key);
		if (clickBank?.[0]?.code) {
			setSelectedBank(clickBank?.[0] ?? { code: null, name: null });
			setSubmitBank({
				...submitBank,
				bank_code: clickBank[0].code,
				is_submit: false,
			});
			setUserValue({
				...userValue,
				accountName: "",
			});
			setCheckBankAccountNumber(undefined);
		}
	};

	/**
	 * 포트원 은행 리스트 조회
	 * @returns {Promise<void>}
	 * [{
	 * code: 은행코드
	 * name: 은행명
	 * }]
	 */
	const getBankList = async () => {
		try {
			const res = await partnersApi.readBankList();
			if (res.code === 200) {
				let list = res.result;
				setBankList(list);
			}
		} catch (e) {
			console.log("e", e);
		}
	};

	const checkPolicy = () => {
		// if (agreement?.policy) return;
		if (isShowPolicy) {
			setAgreement({
				...agreement,
				policy: !agreement?.policy,
			});
			// setIsShowPolicy(false);
		} else {
			window.scrollTo({ top: 0, behavior: "smooth" });
			setIsShowPolicy(true);
		}
	};

	const checkTerms = () => {
		// if (agreement?.terms) return;
		if (isShowTerms) {
			setAgreement({
				...agreement,
				terms: !agreement?.terms,
			});
			// setIsShowTerms(false);
		} else {
			window.scrollTo({ top: 0, behavior: "smooth" });
			setIsShowTerms(true);
		}
	};

	/**
	 * 동의 페이지 확인 버튼 클릭 이벤트 핸들러
	 */
	const handleAgreeClick = () => {
		if (isShowPolicy) {
			setIsShowPolicy(false);
		} else if (isShowTerms) {
			setIsShowTerms(false);
		}
	};

	return (
		<div className="main-wrap" ref={mainRef}>
			{/* 모바일 헤더 추가 */}
			<SignUpHeader title={"추가 정보 입력"} />
			<div className="max-width-apply">
				{/* 기본 정보 */}
				{isShowPolicy ? (
					<Policy />
				) : isShowTerms ? (
					<Terms />
				) : (
					<div className={`signup-form-parents`}>
						<h1
							className={`main-title`}
							id={`main-title`}
							style={{ margin: 0, paddingBottom: "0.5em" }}
						>
							정보 입력
						</h1>
						{/* <div className={`signup-component-list`}>
						<h2>기본 정보</h2>
					</div> */}
						<div className={`signup-form-wrap`}>
							{/* 이름 */}
							<div className={`signup-form-item divider`}>
								<label>
									성함
									<div className={`item-required-div`}>
										<span className={`item-required`}>*</span>
									</div>
								</label>
								<div className={`input-div name`}>
									<Input
										type={`text`}
										placeholder={`성함을 입력해주세요.`}
										value={userValue.name}
										name={"name"}
										onChange={handleInputUser}
										disabled={isLoading}
									/>
								</div>
							</div>
							{/* 휴대폰 번호 */}
							<div className={`signup-form-item divider`}>
								<label>
									휴대폰 번호
									<div className={`item-required-div`}>
										<span className={`item-required`}>*</span>
									</div>
								</label>
								<div className={`input-div phone`}>
									<Input
										type={`text`}
										placeholder={`-를 생략하고 입력해주세요.`}
										value={userValue.phone}
										name={"phone"}
										pattern={`/d*`}
										onChange={handleInputUser}
										disabled={isLoading}
									/>
									{/*<div className="input-div-button">인증요청</div>*/}
								</div>
							</div>
							{/* 주민번호 */}
							<div
								className={`signup-form-item divider`}
								style={{
									flexDirection: "column",
									alignItems: "flex-start",
									gap: "1em",
								}}
							>
								<div className={`signup-form-item`} style={{ width: "100%" }}>
									<label>
										주민등록번호
										<div className={`item-required-div`}>
											<span className={`item-required`}>*</span>
										</div>
									</label>

									<div className={`input-div personal-number`}>
										<div className={`personal-number-layout`}>
											<Input
												type={`text`}
												placeholder={``}
												value={userValue.first_personal_number}
												name={"first_personal_number"}
												onChange={handleInputUser}
												pattern={`/d*`}
												disabled={isLoading}
											/>
											<p>-</p>
										</div>
										<div className={`personal-number-layout`}>
											<Input
												type={`password`}
												placeholder={``}
												value={userValue.second_personal_number}
												name={"second_personal_number"}
												onChange={handleInputUser}
												pattern={`/d*`}
												disabled={isLoading}
											/>
										</div>
										{/* 본인 인증 */}
										<PersonalAuthentication
											certifiedData={certifiedData}
											setCertifiedData={setCertifiedData}
											disabled={
												!userValue.name.length ||
												!userValue.first_personal_number.length ||
												!userValue.second_personal_number.length
											}
										/>
									</div>
								</div>
								<p className={`point-text`}>
									<span>대금 전환</span>을 위하여 주민등록번호를 소득세법 제
									145조에 따라 수집이용하며, 이 법에 따라 5년 동안 보관합니다.
									{"\n"}
									가입시 확인한 본인 인증 정보와 동일한 주민등록 정보가
									제공되어야 합니다.
								</p>
							</div>
							{/* 은행 */}
							<div className={`signup-form-item divider`}>
								<label>
									은행
									<div className={`item-required-div`}>
										<span className={`item-required`}>*</span>
									</div>
								</label>
								<div className={`input-div name`}>
									<Dropdown
										menu={{
											items,
											onClick,
										}}
										trigger={["click"]}
										overlayStyle={{
											maxHeight: 200,
											overflow: "auto",
										}}
									>
										<Input
											loading={isLoading}
											placeholder={"은행을 선택해주세요"}
											value={selectedBank.name}
											style={{
												cursor: "pointer",
											}}
										/>
									</Dropdown>
								</div>
							</div>
							{/* 계좌번호 */}
							<div className={`signup-form-item divider`}>
								<div
									style={{
										// backgroundColor: "red",
										width: "100%",
										display: "flex",
										alignItems: "center",
									}}
								>
									<label>
										계좌 번호
										<div className={`item-required-div`}>
											<span className={`item-required`}>*</span>
										</div>
									</label>
									<div className={`input-div phone`}>
										<Input
											placeholder={`-를 생략하고 입력해주세요`}
											disabled={isLoading}
											value={submitBank.account_number}
											onChange={(e) => {
												setSubmitBank({
													...submitBank,
													account_number: e.target.value?.replace(
														/[^0-9]/g,
														""
													),
													is_submit: false,
												});
												setUserValue({
													...userValue,
													accountName: "",
												});
												setCheckBankAccountNumber(undefined);
											}}
											pattern={`/d*`}
										/>
										{submitBank.is_submit ? (
											<div className="input-div-button-success">
												<img
													src={`/assets/images/success_button_icon.png`}
													alt={`success_button_icon`}
													style={{
														width: "1em",
														height: "auto",
													}}
												/>
												<span>계좌인증</span>
											</div>
										) : (
											<Button
												onClick={() => {
													if (!submitBankLoading) {
														verifyAccount("account");
													}
												}}
												loading={submitBankLoading}
												disabled={
													!userValue.name.length ||
													!userValue.first_personal_number.length ||
													!submitBank.bank_code ||
													!submitBank.account_number ||
													submitBankLoading
												}
												className="certification-request-btn"
											>
												{!submitBankLoading && "인증요청"}
											</Button>
										)}
										{/* <div
										className="input-div-button"
										onClick={() => {
											setIsShowModalCheckBankAccount(true);
											setCheckBankAccountNumber(null);
										}}
									>
										인증요청
									</div> */}
										{/*<div className="input-div-button-success">*/}
										{/*	<img*/}
										{/*		src={`/assets/images/success_button_icon.png`}*/}
										{/*		alt={`success_button_icon`}*/}
										{/*		style={{*/}
										{/*			width: "1em",*/}
										{/*			height: "auto",*/}
										{/*		}}*/}
										{/*	/>*/}
										{/*	<span>본인인증</span>*/}
										{/*</div>*/}
									</div>
								</div>
								{checkBankAccountNumber !== undefined &&
									!submitBank.is_submit && (
										<div
											style={{
												width: "100%",
												display: "flex",
												alignItems: "center",
												marginTop: "10px",
											}}
										>
											<label>인증번호</label>
											<div className={`input-div phone`}>
												<Input
													placeholder={`4자리 숫자를 입력해주세요.`}
													disabled={isLoading}
													value={checkBankAccountNumber}
													onChange={(e) => {
														if (e.target.value?.length <= 4) {
															setCheckBankAccountNumber(
																e.target.value?.replace(/[^0-9]/g, "")
															);
														}
													}}
													pattern={`/d*`}
												/>
												<Button
													onClick={() => {
														if (!submitBankLoading) {
															verifyAccount("code");
														}
														// setIsShowModalCheckBankAccount(true);
														// setCheckBankAccountNumber(null);
													}}
													loading={submitBankLoading}
													disabled={
														!submitBank.bank_code ||
														!submitBank.account_number ||
														!checkBankAccountNumber
													}
													className="certification-request-btn"
												>
													{!submitBankLoading && "확인"}
												</Button>
												{/*<div*/}
												{/*	className="input-div-button"*/}
												{/*	onClick={() => {*/}
												{/*		setIsShowModalSuccessBankAccount(true);*/}
												{/*	}}*/}
												{/*>*/}
												{/*	확인*/}
												{/*</div>*/}
											</div>
										</div>
									)}
								{checkBankAccountNumber !== undefined &&
									!submitBank.is_submit && (
										<img
											src={`/assets/images/bank_account_image.png`}
											alt={`bank_account_image`}
											style={{
												width: "16em",
												height: "auto",
												position: "absolute",
												right: -6,
											}}
										/>
									)}
							</div>
							{/* <div className={`signup-form-item divider`}>
							<label>
								계좌 번호
								<div className={`item-required-div`}>
									<span className={`item-required`}>*</span>
								</div>
							</label>
							<div className={`input-div phone`}>
								<Input
									placeholder={`-를 생략하고 입력해주세요`}
									disabled={isLoading}
									value={submitBank.account_number}
									onChange={(value) =>
										setSubmitBank({
											...submitBank,
											account_number: value,
										})
									}
									pattern={`/d*`}
								/>
								<div
									className="input-div-button"
									onClick={() => {
										setIsShowModalCheckBankAccount(true);
										setCheckBankAccountNumber(null);
									}}
								>
									인증요청
								</div>
							</div>
						</div> */}
							{/* 계좌 소유자 */}
							<div className={`signup-form-item divider`}>
								<label>
									계좌 소유자
									<div className={`item-required-div`}>
										<span className={`item-required`}>*</span>
									</div>
								</label>
								<div className={`input-div name`}>
									<Input
										type={`text`}
										placeholder={``}
										value={userValue.accountName}
										name={"accountName"}
										// onChange={handleInputUser}
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* 대금 정산 및 지급을 위한 정보 수집 동의 */}
				<div className={`signup-form-parents`} style={{ marginTop: "3em" }}>
					<div className={`signup-component-list`}>
						<h2 style={{ margin: 0 }}>
							대금 정산 및 지급을 위한 정보 수집 동의
						</h2>
					</div>
					<p className={`point-text`} style={{ fontSize: "1.1em" }}>
						당영투게더가 계좌 인증 및 정산 대금 지급을 위한 목적으로
						개인정보(계좌정보 포함) 및 고유식별정보를 수집 및 이용하며, 회원의
						개인정보를 안전하게 취급하는데 최선을 다합니다.
					</p>

					<div className={`signup-form-wrap`}>
						{!isShowTerms && (
							<div
								className={`signup-agreement-wrap`}
								onClick={checkPolicy}
								style={{ cursor: "pointer" }}
							>
								<div className={`signup-agreement-div`}>
									<img
										src={`/assets/images/check-${
											agreement.policy ? "active" : "disabled"
										}.png`}
										alt={`약관 동의 1`}
									/>
									<p>[필수] 개인정보 처리방침</p>
									{!isShowPolicy && (
										<img
											src={`/assets/images/channel/right-arrow.png`}
											alt={`화살표`}
											style={{
												height: "2em",
												width: "2em",
												marginLeft: "auto",
											}}
											// onClick={(e) => {
											// 	e.stopPropagation();
											// 	window.open("https://www.dangyoung.com/policy", "_blank");
											// }}
										/>
									)}
								</div>
								<p style={{ color: "#a9b0b7" }}>
									회원님의 소중한 정보를 안전하게 지키겠다는 약속이에요.
								</p>
							</div>
						)}

						{!isShowPolicy && (
							<div
								className={`signup-agreement-wrap`}
								onClick={checkTerms}
								style={{ cursor: "pointer" }}
							>
								<div className={`signup-agreement-div`}>
									<img
										src={`/assets/images/check-${
											agreement.terms ? "active" : "disabled"
										}.png`}
										alt={`약관 동의 2`}
									/>
									<p>[필수] 고유식별정보 수집 및 이용</p>
									{!isShowTerms && (
										<img
											src={`/assets/images/channel/right-arrow.png`}
											alt={`화살표`}
											style={{
												height: "2em",
												width: "2em",
												marginLeft: "auto",
											}}
											// onClick={(e) => {
											// 	e.stopPropagation();
											// 	window.open("https://www.dangyoung.com/terms", "_blank");
											// }}
										/>
									)}
								</div>
								<p style={{ color: "#a9b0b7" }}>
									회원님에게 안정적인 대금 정산을 하겠다는 약속이에요.
								</p>
							</div>
						)}
					</div>
				</div>
				<div
					className={`signup-blue-button-wrap`}
					style={isShowPolicy || isShowTerms ? { paddingTop: 0 } : {}}
				>
					{isShowPolicy || isShowTerms ? (
						<Button className={`signup-blue-button`} onClick={handleAgreeClick}>
							확인
						</Button>
					) : (
						<Button
							loading={isLoading}
							className={`signup-blue-button`}
							onClick={handleSignupButton}
						>
							완료
						</Button>
					)}
				</div>
				<Row justify={"center"}>
					<button
						className={`channel-withdraw`}
						style={{ color: "#ced4da" }}
						onClick={logout}
					>
						로그아웃
					</button>
				</Row>
			</div>
			{/* 모달 - 1원 입금 */}
			<Modal
				title=""
				open={isShowModalCheckBankAccount}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title" style={{ marginBottom: "10px" }}>
					입력하신 계좌로 1원을 보냈습니다.
				</div>
				<div
					className="modal-title"
					style={{ marginBottom: "-4px", paddingTop: 0 }}
				>
					거래내역 내 입금자명에 적힌
				</div>
				<div
					className="modal-title"
					style={{ paddingTop: 0, marginBottom: "20px" }}
				>
					<span>숫자 4자리</span>를 확인하여 입력해주세요.
				</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setIsShowModalCheckBankAccount(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
			{/* 모달 - 계좌 인증 완료 */}
			<Modal
				title=""
				open={isShowModalSuccessBankAccount}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title">계좌인증이 완료되었습니다.</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setIsShowModalSuccessBankAccount(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default TogetherSignup;
