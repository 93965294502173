import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import PageLayout from "./components/layout/PageLayout";
import { loginLog } from "./utils/useUser";

function App() {
	//
	const sessionInflow = sessionStorage.getItem("inflow");
	const oneTime = sessionStorage.getItem("oneTime");
	const [inflowState, setInflowState] = useState("");

	const inflowLog = () => {
		if (process.env.REACT_APP_MODE === "prod") {
			if (sessionInflow) {
				if (!oneTime) {
					const param = {
						// id: Number(moment(date).format("MMDDHHmmss"))
						id: "3000",
					};
					loginLog(param, "투게더 유입", sessionInflow);
					sessionStorage.setItem("oneTime", "oneTime");
				}
			} else if (
				!sessionInflow?.includes("localhost") ||
				!sessionInflow?.includes("together")
			) {
				const inflow = document?.referrer ? document.referrer : "no-referrer";
				sessionStorage.setItem("inflow", inflow);
				setInflowState(inflow);
			} else {
				sessionStorage.setItem("inflow", "no-referrer");
			}
		}
	};

	useEffect(() => {
		inflowLog();
	}, [inflowState]);
	return (
		<BrowserRouter>
			<PageLayout />
		</BrowserRouter>
	);
}

export default App;
