import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";

import dayjs from "dayjs";
import Swal from "sweetalert2";

import inquiryApi from "../../apis/Inquiry.api";
import { setLoading } from "../../redux/store/common/commonDuck";
import CustomPagination from "../common/CustomPagination";

const InquiryList = ({
	inquiryList,
	setInquiryList,
	totalCnt,
	setTotalCnt,
	readInquiryList,
	pageSize,
}) => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [currPage, setCurrPage] = useState(1);
	const [selectId, setSelectId] = useState(null);
	const [mediaResult, setMediaResult] = useState(null);
	const [openAnswerTables, setOpenAnswerTables] = useState(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
		if (typeof window !== "undefined") {
			const matchQueryList = window.matchMedia("all and (max-width: 768px)");
			setMediaResult(matchQueryList.matches);

			const media = function handleChange(e) {
				setMediaResult(e.matches);
			};

			matchQueryList.addEventListener("change", media);
		}
	}, []);

	useEffect(() => {
		if (user?.id) {
			readInquiryList();
		}
	}, [user]);

	/**
	 * 문의 읽음 처리하기
	 * @param inquiry
	 * @returns {Promise<void>}
	 */
	const changeInquiryRead = async (inquiry) => {
		try {
			const param = {
				id: inquiry.id,
				created_by: user?.id ?? 0,
			};
			await inquiryApi.readInquiry(param);
		} catch (e) {
			console.log("changeInquiryRead :: error = ", e);
		}
	};
	/**
	 * page 넘김
	 */
	const handleChangePagination = async (pageNumber, type) => {
		try {
			setIsLoading(true);
			const currOffset = (pageNumber - 1) * pageSize;
			setCurrPage(pageNumber);
			let param = {
				user_id: user?.id ?? 0,
				isApp: false,
				isTogether: true,
				offset: currOffset,
				pageSize: pageSize,
			};
			const res = await inquiryApi.readInquiryListByUser(param);
			if (res.code === 200) {
				setTotalCnt(res.result?.total);
				setInquiryList(res.result?.csInquiryList);
			}
			if (type !== "query") {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}
		} catch (e) {
			console.log("handleChangePagination :: error = ", e);
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: "예기치 못한 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.",
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (mediaResult) setOpenAnswerTables(false);
	}, [mediaResult]);

	return (
		<>
			<div className="max-width-apply">
				<div className="calculate-inquiry-table" style={{ padding: "0 0" }}>
					{!!isLoading && (
						<div
							style={{
								width: "100%",
								position: "fixed",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								zIndex: 999,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(0,0,0,0.2)",
							}}
						>
							<Spin size={"large"} />
						</div>
					)}
					{!mediaResult && !openAnswerTables && (
						<p
							style={{
								fontSize: "1em",
								fontWeight: 600,
								paddingBottom: "0.8em",
							}}
						>
							총 {totalCnt}개
						</p>
					)}
					{!openAnswerTables && (
						<table className={`${inquiryList?.length ? "" : "no-data"}`}>
							<thead>
								<tr>
									<th>답변상태</th>
									<th>내용</th>
									<th>작성일</th>
								</tr>
							</thead>
							<tbody>
								{inquiryList?.length ? (
									<>
										{inquiryList.map((inquiry) => (
											<>
												<tr
													key={inquiry.id}
													className={`inquiry`}
													onClick={() => {
														if (selectId === inquiry.id) {
															setSelectId(null);
															setOpenAnswerTables(false);
														} else {
															setSelectId(inquiry.id);
															if (!mediaResult) {
																setOpenAnswerTables(true);
															}
															if (inquiry.answer_status && !inquiry.is_read) {
																changeInquiryRead(inquiry);
															}
														}
													}}
												>
													<td
														className={
															inquiry.answer_status
																? "td-status complete"
																: "td-status"
														}
													>
														{inquiry.answer_status ? "답변 완료" : "답변 대기"}
													</td>
													<td
														className="td-content"
														style={{
															textOverflow: "ellipsis",
															whiteSpace: "nowrap",
															overflow: "hidden",
														}}
													>
														<div className="pc">{inquiry.question_content}</div>
														<div className="mobile">
															{
																inquiry.question_content
																	.split("]")[0]
																	.split("[")[1]
															}
														</div>
													</td>
													<td className="td-date">
														{mediaResult
															? dayjs(inquiry.created_at).format(
																	"YYYY년 MM월 DD일 HH:mm"
																)
															: dayjs(inquiry.created_at).format("YYYY.MM.DD.")}
													</td>
												</tr>
												{/* ------- 답변 내용 영역 ------- */}
												{inquiry.id === selectId && mediaResult && (
													<tr className="detail-area">
														<div className="content">
															<strong>Q.</strong>
															<p>{inquiry.question_content.split("]")[1]}</p>
														</div>
														{inquiry.answer_content && (
															<div className="answer">
																<strong>A.</strong>
																<p>{inquiry.answer_content}</p>
															</div>
														)}
													</tr>
												)}
											</>
										))}
									</>
								) : (
									<tr className={`calculate-inquiry-table-noData-row`}>
										<td colSpan={3} className="calculate-inquiry-table-noData">
											문의내역이 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
				</div>
				{inquiryList?.length && !openAnswerTables ? (
					<CustomPagination
						pageSize={pageSize}
						totalCount={totalCnt}
						currPageNum={currPage}
						pageClick={handleChangePagination}
						customStyle={{
							marginTop: 0,
							marginBottom: "3em",
						}}
					/>
				) : (
					<></>
				)}
			</div>
			{/* pc 답변 */}
			{inquiryList?.length > 0 &&
				openAnswerTables &&
				!mediaResult &&
				inquiryList.map((inquiry) => {
					return (
						inquiry.id === selectId && (
							<div>
								<div className="max-width-apply inquiry">
									<p className="inquiry-detail-box-title">Q 문의 내용</p>
									<table className="inquiry-detail-box">
										<tbody>
											<tr>
												<td>작성일</td>
												<td>
													{dayjs(inquiry.created_at).format("YYYY.MM.DD.")}
												</td>
												<td>답변상태</td>
												<td>
													{inquiry.answer_status ? "답변 완료" : "답변 대기"}
												</td>
											</tr>
											<tr>
												<td>이름</td>
												<td> {inquiry.name}</td>
												<td>휴대폰 번호</td>
												<td>
													{inquiry.company_contact.slice(0, 3)}-
													{inquiry.company_contact.slice(3, 7)}-
													{inquiry.company_contact.slice(7, 11)}
												</td>
											</tr>
											<tr>
												<td>문의 내용</td>
												<td colSpan={3}>
													{inquiry.question_content.split("]")[0].split("[")[1]}
												</td>
											</tr>
											<tr>
												<td>내용</td>
												<td colSpan={3}>
													{inquiry.question_content.split("]\n")[1]}
												</td>
											</tr>
										</tbody>
									</table>
									{inquiry.answer_content && (
										<>
											<p className="inquiry-detail-box-title">Q 답변</p>
											<table className="inquiry-detail-box">
												<tbody>
													<tr>
														<td>답변</td>
														<td>
															{
																inquiry.question_content
																	.split("]")[0]
																	.split("[")[1]
															}
															에 대한 답변입니다.
														</td>
														<td>답변 일자</td>
														<td>
															{dayjs(inquiry.answer_updated_at).format(
																"YYYY.MM.DD."
															)}
														</td>
													</tr>
													<tr>
														<td style={{ verticalAlign: "top" }}>답변 내용</td>
														<td colSpan={3}>{inquiry.answer_content}</td>
													</tr>
												</tbody>
											</table>
										</>
									)}
									<button
										type="button"
										className="inquiry-go-list-btn"
										onClick={() => {
											setOpenAnswerTables(false);
										}}
									>
										목록으로
									</button>
								</div>
							</div>
						)
					);
				})}
		</>
	);
};

export default InquiryList;
