import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { DatePicker, Radio, Row, Skeleton } from "antd";
import moment from "moment";

import orderApi from "../../apis/Order.api";
import ApiService from "../../utils/ApiService";
import { toastAlert } from "../AlertComponent";

import { negativeColor, positiveColor } from "./BannerAlgorithm";

const api = new ApiService();

const TodaySales = ({ mediaResult }) => {
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(false);

	const [dateType, setDateType] = useState("today");
	const [searchOptions, setSearchOptions] = useState({
		startDate: moment().startOf("day"),
		endDate: moment().endOf("day"),
	});
	const [statistics, setStatistics] = useState({
		total: 0,
		compare_total: 0,
		compare_count: 0,
		compare_expect: 0,
	});

	const [compareList, setCompareList] = useState([
		{
			name: "구매 건수",
			count: 0,
			condition: 0,
			unit: "건",
		},
		{
			name: "거래액",
			count: 0,
			condition: 0,
			unit: "원",
		},
		{
			name: "획득 골드",
			count: 0,
			condition: 0,
			unit: "G",
		},
	]);

	const [dataChart, setDataChart] = useState([]);

	const dateTypeItems = [
		{ label: "오늘", value: "today" },
		{ label: "1주일", value: "1week" },
		{ label: "1개월", value: "1month" },
	];

	// 차트 숨김 처리 (2024.08.19) - ksb
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			position: "top",
			align: "center",
			display: false,
		},
		labels: {
			display: false,
		},
		tooltips: {
			intersect: false,
			position: "nearest",
			callbacks: {
				label: function (tooltipItem, data) {
					let tooltipsList = [];
					const item = data.datasets[0].item[tooltipItem.index];
					const purchasesNum = item?.purchases_num ?? 0;
					const expected_profit_amount = item?.expected_profit_amount ?? 0;
					let value = data.datasets[0].data[tooltipItem.index];
					tooltipsList.push(`구매 건수: ${purchasesNum.toLocaleString()}건`);
					tooltipsList.push(`거래액: ${value.toLocaleString()}원`);
					tooltipsList.push(
						`획득 골드: ${(expected_profit_amount * 10).toLocaleString()}G`
					);
					return tooltipsList;
				},
			},
		},
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: false,
					},
					ticks: {
						display: false, // it should work
						beginAtZero: true,
					},
					gridLines: {
						display: false,
					},
					afterDataLimits: (scale) => {
						scale.max = scale.max * 1.2;
						// 정해진 범위만큼 보일건지, 그 이상으로 넓혀지게 보일건지
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	};

	// 현금 -> 골드 계산 추가 (2024.08.08) - ksb
	const data = {
		labels: dataChart?.length
			? dataChart?.map((item) => moment(item.create_date).format("MM/DD"))
			: [],
		datasets: [
			{
				// label: '댓글',
				data: dataChart?.length
					? dataChart?.map((item) => item.total_purchase_amount)
					: [],
				borderColor:
					statistics?.compare_total > 0 ? positiveColor : negativeColor,
				// "#df1d1d" : "#117ffb",
				backgroundColor:
					statistics?.compare_total > 0
						? "rgba(53, 162, 235, 0.1)"
						: "rgba(223, 29, 29, 0.1)",
				borderWidth: 2,
				item: dataChart?.length ? dataChart : [],
			},
		],
	};

	useEffect(() => {
		if (user?.id) {
			if (searchOptions.startDate === null || searchOptions.endDate === null) {
				toastAlert("조회하려는 기간이 없습니다. 다시 설정해주시길 바랍니다.");
				return false;
			} else if (searchOptions?.startDate && searchOptions?.endDate) {
				getStatistics();
			}
			// const timer = setTimeout(() => {
			// 	getStatistics();
			// }, 100);
			// return () => clearTimeout(timer);
		}
	}, [user, searchOptions]);

	// 차트 숨김 처리 및 기간별 검색 추가 (2024.08.19) - ksb
	const getStatistics = async () => {
		setLoading(true);
		const param = {
			userId: user?.id,
		};
		const res = await api.getProductLinkList(param);
		if (res.data.code === 200 && res.data.result?.length) {
			const linkList = res.data.result.map((link) => link.query);
			const query = linkList.join(",");

			let duplicateStartDate = moment(searchOptions.startDate.startOf("day"));
			let duplicateEndDate = moment(searchOptions.startDate.startOf("day"));

			const paramBefore = {
				startDate: duplicateStartDate
					.subtract(
						Math.floor(
							moment
								.duration(searchOptions.endDate.diff(searchOptions.startDate))
								.asDays()
						) + 1,
						"days"
					)
					.startOf("days")
					.format("YYYYMMDDHHmm"),
				endDate: duplicateEndDate
					.subtract(1, "days")
					.endOf("days")
					.format("YYYYMMDDHHmm"),
				queryList: query,
			};
			const paramNow = {
				startDate: searchOptions.startDate
					.startOf("day")
					.format("YYYYMMDDHHmm"),
				endDate: searchOptions.endDate.endOf("day").format("YYYYMMDDHHmm"),
				queryList: query,
			};
			const resB = await orderApi.orderStatistics(paramBefore);
			const resN = await orderApi.orderStatistics(paramNow);

			let beforeValue = {
				total: 0,
				count: 0,
				expect: 0,
				combine: 0,
			};
			let nowValue = {
				total: 0,
				count: 0,
				expect: 0,
				combine: 0,
			};

			if (resB.code === 200) {
				const before = resB.result;

				beforeValue = {
					total: before?.total_purchase_amount ?? 0,
					count: before?.purchases_num ?? 0,
					expect: before?.expected_profit_amount ?? 0,
					combine: before?.combined_amount ?? 0,
				};
			}
			if (resN.code === 200) {
				const now = resN.result;
				nowValue = {
					total: now?.total_purchase_amount ?? 0,
					count: now?.purchases_num ?? 0,
					expect: now?.expected_profit_amount ?? 0,
					combine: now?.combined_amount ?? 0,
				};
			}
			// 현금 -> 골드 계산 추가 (2024.08.08) - ksb
			setStatistics({
				total: nowValue?.total,
				compare_total: nowValue?.total - beforeValue?.total,
				compare_count: nowValue?.count - beforeValue?.count,
				compare_expect: nowValue?.expect - beforeValue?.expect, // 사용 X (2024.08.08) - ksb
			});
			setCompareList([
				{
					name: "구매 건수",
					count: nowValue?.count,
					condition: nowValue?.count - beforeValue?.count,
					unit: "건",
				},
				{
					name: "거래액",
					count: nowValue?.total,
					condition: nowValue?.total - beforeValue?.total,
					unit: "원",
				},
				{
					name: "획득 골드",
					count: nowValue?.expect * 10,
					condition: nowValue?.expect - beforeValue?.expect,
					unit: "G",
				},
			]);
		}
		setLoading(false);
	};
	// const getStatistics = useCallback(async () => {
	// 	console.log("getStatistics :: searchOptions = ", searchOptions);
	// 	if (searchOptions.startDate === null || searchOptions.endDate === null) {
	// 		toastAlert("조회하려는 기간이 없습니다. 다시 설정해주시길 바랍니다.");
	// 		return false;
	// 	} else {
	// 		if (!searchOptions.startDate.isBefore(searchOptions.endDate)) {
	// 			toastAlert(
	// 				"조회하려는 기간이 잘못 설정되어있습니다. 다시 시도해주시길 바랍니다."
	// 			);
	// 			return false;
	// 		} else {
	// 			setLoading(true);
	// 			const param = {
	// 				userId: user?.id,
	// 			};
	// 			const res = await api.getProductLinkList(param);
	// 			if (res.data.code === 200 && res.data.result?.length) {
	// 				const linkList = res.data.result.map((link) => link.query);
	// 				const query = linkList.join(",");
	//
	// 				const paramBefore = {
	// 					startDate: moment()
	// 						.subtract(1, "days")
	// 						.startOf("days")
	// 						.format("YYYYMMDDHHmm"),
	// 					endDate: moment()
	// 						.subtract(1, "days")
	// 						.endOf("days")
	// 						.format("YYYYMMDDHHmm"),
	// 					queryList: query,
	// 				};
	// 				const paramNow = {
	// 					startDate: searchOptions.startDate
	// 						.startOf("day")
	// 						.format("YYYYMMDDHHmm"),
	// 					endDate: searchOptions.endDate.endOf("day").format("YYYYMMDDHHmm"),
	// 					// startDate: moment().startOf("days").format("YYYYMMDDHHmm"),
	// 					// endDate: moment().endOf("days").format("YYYYMMDDHHmm"),
	// 					queryList: query,
	// 				};
	// 				// const paramWeek = {
	// 				// 	startDate: moment()
	// 				// 		.subtract(7, "days")
	// 				// 		.startOf("days")
	// 				// 		.format("YYYYMMDDHHmm"),
	// 				// 	endDate: moment().endOf("days").format("YYYYMMDDHHmm"),
	// 				// 	queryList: query,
	// 				// 	dateType: `weekly`,
	// 				// };
	// 				const resB = await orderApi.orderStatistics(paramBefore);
	// 				const resN = await orderApi.orderStatistics(paramNow);
	// 				// const resW = await orderApi.orderStatistics(paramWeek);
	//
	// 				let beforeValue = {
	// 					total: 0,
	// 					count: 0,
	// 					expect: 0,
	// 					combine: 0,
	// 				};
	// 				let nowValue = {
	// 					total: 0,
	// 					count: 0,
	// 					expect: 0,
	// 					combine: 0,
	// 				};
	//
	// 				if (resB.code === 200) {
	// 					const before = resB.result;
	//
	// 					beforeValue = {
	// 						total: before?.total_purchase_amount ?? 0,
	// 						count: before?.purchases_num ?? 0,
	// 						expect: before?.expected_profit_amount ?? 0,
	// 						combine: before?.combined_amount ?? 0,
	// 					};
	// 				}
	// 				if (resN.code === 200) {
	// 					const now = resN.result;
	// 					nowValue = {
	// 						total: now?.total_purchase_amount ?? 0,
	// 						count: now?.purchases_num ?? 0,
	// 						expect: now?.expected_profit_amount ?? 0,
	// 						combine: now?.combined_amount ?? 0,
	// 					};
	// 				}
	// 				// if (resW.code === 200) {
	// 				// 	let list = resW.result?.statisticsList;
	// 				// 	setDataChart(list);
	// 				// }
	// 				// 현금 -> 골드 계산 추가 (2024.08.08) - ksb
	// 				setStatistics({
	// 					total: nowValue?.total,
	// 					compare_total: nowValue?.total - beforeValue?.total,
	// 					compare_count: nowValue?.count - beforeValue?.count,
	// 					compare_expect: nowValue?.expect - beforeValue?.expect, // 사용 X (2024.08.08) - ksb
	// 				});
	// 				setCompareList([
	// 					{
	// 						name: "구매 건수",
	// 						count: nowValue?.count,
	// 						condition: nowValue?.count - beforeValue?.count,
	// 						unit: "건",
	// 					},
	// 					{
	// 						name: "거래액",
	// 						count: nowValue?.total,
	// 						condition: nowValue?.total - beforeValue?.total,
	// 						unit: "원",
	// 					},
	// 					{
	// 						name: "획득 골드",
	// 						count: nowValue?.expect * 10,
	// 						condition: nowValue?.expect - beforeValue?.expect,
	// 						unit: "G",
	// 					},
	// 				]);
	// 			}
	// 			setLoading(false);
	// 		}
	// 	}
	// }, []);

	/**
	 * handle dateType change
	 */
	const handleDateTypeChange = (e) => {
		const value = e.target.value;
		setDateType(value);
		switch (value) {
			case "today":
				setSearchOptions({
					...searchOptions,
					startDate: moment().startOf("day"),
					endDate: moment().endOf("day"),
				});
				break;
			case "1week":
				if (searchOptions.endDate === null || searchOptions.endDate === "") {
					setSearchOptions({
						...searchOptions,
						startDate: moment().subtract(7, "days").startOf("day"),
						endDate: moment().endOf("day"),
					});
				} else {
					setSearchOptions({
						...searchOptions,
						startDate: moment(searchOptions.endDate.format("YYYY/MM/DD"))
							.subtract(7, "days")
							.startOf("day"),
					});
				}
				break;
			case "1month":
				if (searchOptions.endDate === null || searchOptions.endDate === "") {
					setSearchOptions({
						...searchOptions,
						startDate: moment().subtract(1, "months").startOf("day"),
						endDate: moment().endOf("day"),
					});
				} else {
					setSearchOptions({
						...searchOptions,
						startDate: moment(searchOptions.endDate.format("YYYY/MM/DD"))
							.subtract(1, "months")
							.startOf("day"),
					});
				}
				break;
		}
	};

	let normalUser = !user?.role || user?.role === "level_2";
	return (
		<div
			className={`dashboard-component`}
			style={
				!normalUser
					? mediaResult
						? { width: "100%" }
						: { width: "49%" }
					: { width: "100%" }
			}
		>
			<h3>금일 매출 실적</h3>
			{/*조회 기간*/}
			<Row
				justify="start"
				align="middle"
				style={{ paddingBottom: "0.5em", gap: 5 }}
			>
				<Radio.Group
					className="dashboard-component-search-select-type-wrap"
					options={dateTypeItems}
					onChange={handleDateTypeChange}
					value={dateType}
					optionType="button"
				/>
				<Row justify="start" align="middle" style={{ gap: 5 }}>
					<DatePicker
						className="dashboard-component-search-date-wrap"
						format="YYYY/M/DD"
						value={searchOptions.startDate}
						onChange={(value) => {
							setDateType("");
							setSearchOptions({ ...searchOptions, startDate: value });
						}}
						allowClear={false}
					/>
					<span className="dashboard-component-search-date-sign">~</span>
					<DatePicker
						className="dashboard-component-search-date-wrap"
						format="YYYY/M/DD"
						value={searchOptions.endDate}
						onChange={(value) => {
							setDateType("");
							setSearchOptions({ ...searchOptions, endDate: value });
						}}
						style={{ marginRight: 2 }}
						allowClear={false}
					/>
				</Row>
			</Row>

			<div className={`dashboard-component-chart-bottom`}>
				<div className={`dashboard-big-price-div`}>
					{/* 금일 실적액 표기 */}
					<div className={`dashboard-point-with-img-div`}>
						<Skeleton
							loading={loading}
							active={loading}
							size={"large"}
							block={true}
							className={`skeleton-point-price`}
							style={{
								height: "6em",
							}}
							title={null}
							paragraph={{
								rows: 2,
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "baseline",
									gap: 5,
								}}
							>
								<h1>{statistics?.total?.toLocaleString()}</h1>
								<b>원</b>
								<img
									src={`/assets/images/dashboard/${
										statistics?.compare_total > 0
											? "blue-up"
											: statistics?.compare_total < 0
												? "red-down"
												: "middle"
									}.png`}
									alt={`대시보드 경고 아이콘`}
									style={{
										width: "1.8em",
										height: "1.8em",
										marginLeft: 5,
									}}
								/>
							</div>
							<p className={`dark-gray`}>
								이전 대비
								<span
									style={{
										color:
											statistics?.compare_total > 0
												? // ? "#df1d1d"
													positiveColor
												: statistics?.compare_total < 0
													? // ? "#117ffb"
														negativeColor
													: "inherit",
									}}
								>
									{" "}
									{Math.abs(statistics?.compare_total)?.toLocaleString()}원{" "}
									{statistics?.compare_total >= 0 ? "증가" : "감소"}
								</span>
							</p>
						</Skeleton>
					</div>

					{/* 구매 건수, 거래액, 예상 수익 - mobile-hidden */}
					<div className={`dashboard-point-with-img-div point-list`}>
						<Skeleton
							loading={loading}
							active={loading}
							size={"large"}
							block={true}
							style={{
								height: "6em",
								paddingLeft: "1.2em",
							}}
							title={null}
							paragraph={{
								rows: 3,
								width: "100%",
							}}
						>
							{compareList?.map((item, idx) => (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										padding: "0.5em 0 0.5em 1.2em",
									}}
									key={`dashboard-today-${idx}`}
								>
									<p className={`dark-gray`}>{item.name}</p>
									<div style={{ display: "flex", alignItems: "center" }}>
										<p>
											{item.count?.toLocaleString()} <small>{item.unit}</small>
										</p>
										<img
											src={`/assets/images/dashboard/${
												item?.condition > 0
													? "up"
													: item?.condition < 0
														? "down"
														: "middle"
											}.png`}
											alt={`대시보드 경고 아이콘`}
											style={{
												width: "1.1em",
												height: "1.1em",
												marginLeft: 6,
											}}
										/>
									</div>
								</div>
							))}
						</Skeleton>
					</div>
				</div>
			</div>

			{/*<div*/}
			{/*	className={`border-top my-line-chart-div`}*/}
			{/*	style={{*/}
			{/*		position: "relative",*/}
			{/*		width: "100%",*/}
			{/*		maxHeight: "16rem",*/}
			{/*		padding: "1.5em 0 0",*/}
			{/*	}}*/}
			{/*>*/}
			{/*	{loading ? (*/}
			{/*		<Skeleton.Button*/}
			{/*			loading={loading}*/}
			{/*			active={loading}*/}
			{/*			size={"large"}*/}
			{/*			block={true}*/}
			{/*			style={{*/}
			{/*				height: "18vh",*/}
			{/*			}}*/}
			{/*			title={null}*/}
			{/*		/>*/}
			{/*	) : (*/}
			{/*		<div className={"my-line-chart-custom"}>*/}
			{/*			<Line options={options} data={data} />*/}
			{/*		</div>*/}
			{/*	)}*/}
			{/*	{!dataChart?.length && !loading && (*/}
			{/*		<Row*/}
			{/*			align={"middle"}*/}
			{/*			style={{*/}
			{/*				width: "100%",*/}
			{/*				flexDirection: "column",*/}
			{/*				padding: "3em 0",*/}
			{/*				position: "absolute",*/}
			{/*				top: "50%",*/}
			{/*				left: "50%",*/}
			{/*				transform: "translate(-50%, -50%)",*/}
			{/*				height: "100%",*/}
			{/*				justifyContent: "center",*/}
			{/*				flexWrap: "nowrap",*/}
			{/*			}}*/}
			{/*		>*/}
			{/*			<img*/}
			{/*				src={`/assets/images/warning.png`}*/}
			{/*				alt={`warning-icon`}*/}
			{/*				style={{*/}
			{/*					width: "3em",*/}
			{/*					height: "auto",*/}
			{/*				}}*/}
			{/*			/>*/}
			{/*			<p*/}
			{/*				style={{*/}
			{/*					marginTop: "1em",*/}
			{/*					color: "#adb5bd",*/}
			{/*					fontSize: "1.1em",*/}
			{/*					fontWeight: 500,*/}
			{/*				}}*/}
			{/*			>*/}
			{/*				실적 내역이 없습니다.*/}
			{/*			</p>*/}
			{/*		</Row>*/}
			{/*	)}*/}
			{/*</div>*/}

			{/* 구매 건수, 거래액, 예상 수익 - mobile-show */}
			<div
				className={`dashboard-point-with-img-div point-list point-list-mobile`}
			>
				<Skeleton
					loading={loading}
					active={loading}
					size={"large"}
					block={true}
					style={{
						height: "6em",
						paddingTop: "1.2em",
					}}
					title={null}
					paragraph={{
						rows: 3,
						width: "100%",
					}}
				>
					{compareList?.map((item, idx) => (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
								padding: "0.5em 0",
							}}
							key={`dashboard-today-${idx}`}
						>
							<p className={`dark-gray`}>{item.name}</p>
							<div style={{ display: "flex", alignItems: "center" }}>
								<p>
									{item.count?.toLocaleString()} <small>{item.unit}</small>
								</p>
								<img
									src={`/assets/images/dashboard/${
										item?.condition > 0
											? "up"
											: item?.condition === 0
												? "middle"
												: "down"
									}.png`}
									alt={`대시보드 경고 아이콘`}
									style={{
										width: "1.1em",
										height: "1.1em",
										marginLeft: 6,
									}}
								/>
							</div>
						</div>
					))}
				</Skeleton>
			</div>

			<div className={`dashboard-component-footer`}>
				<Link to={`/reports`} className={`dashboard-footer-font`}>
					수익 리포트로 이동 {">"}
				</Link>
			</div>
		</div>
	);
};

export default TodaySales;
