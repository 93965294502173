import { useLocation } from "react-router-dom";

import { Layout } from "antd";

import MetaTag from "../MetaTag";

import ContentComponent from "./ContentComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import InquiryGuideFooterComponent from "./InquiryGuideFooterComponent";

const { Header, Footer, Content } = Layout;

const PageLayout = () => {
	const location = useLocation();
	const pathname = location.pathname;
	return (
		<>
			<MetaTag />
			<Layout style={{ minHeight: "100%" }}>
				{/* 헤더 */}
				{location.pathname !== "/404" &&
					(location.pathname.includes("user") ? (
						<Header
							className={"header together-header-height hidden"}
							style={{ padding: 0 }}
						>
							<HeaderComponent />
						</Header>
					) : (
						<Header
							className={
								pathname.includes("dashboard")
									? `header together-header-height sky`
									: `header together-header-height`
							}
							// className={`header together-header-height ${
							// 	location.pathname === "/" ? "together-header" : ""
							// }`}
							style={{ padding: 0 }}
						>
							<HeaderComponent />
						</Header>
					))}
				{/* 내용 */}
				<Content
					className="content"
					style={
						location.pathname.includes("/dashboard") ||
						location.pathname === "/user"
							? {
									backgroundColor: "#f8f9fa",
								}
							: {}
					}
				>
					<ContentComponent />
				</Content>
				{/* 하단 */}
				{location.pathname.includes("/dashboard") && (
					<InquiryGuideFooterComponent />
				)}
				{location.pathname !== "/404" &&
				!location.pathname.includes("user") &&
				location.pathname !== "/products/link" &&
				!location.pathname.includes("/gold/settlement") &&
				location.pathname !== "/policy" &&
				location.pathname !== "/terms" ? (
					<Footer className="footer">
						<FooterComponent />
					</Footer>
				) : (
					<></>
				)}
			</Layout>
		</>
	);
};

export default PageLayout;
