import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Dropdown, Input, Modal, Row, Spin } from "antd";
import moment from "moment";

import Swal from "sweetalert2";

import partnersApi from "../../apis/Partners.api";
import { setChannelUser, setUser } from "../../redux/store/auth/authDuck";
import { setLoading } from "../../redux/store/common/commonDuck";
import ApiService from "../../utils/ApiService";
import { isGeneralBank } from "../../utils/status";

const api = new ApiService();

const initialFormValue = {
	name: "",
	phone: "",
	bank_code: "",
	bank_name: "",
	account_number: "",
	account_holder_name: "",
	is_verify_account: true,
};

const EditInfo = ({ setActive }) => {
	const phonePattern = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.common.loading);
	const setIsLoading = (value) => dispatch(setLoading(value));

	const [currUserValue, setCurrUserValue] = useState(null);
	const [formValue, setFormValue] = useState(initialFormValue);
	const [formLoading, setFormLoading] = useState(false);
	const [bankList, setBankList] = useState([]);
	const [submitBankLoading, setSubmitBankLoading] = useState(false);
	const [checkBankAccountNumber, setCheckBankAccountNumber] =
		useState(undefined);
	const [isShowModalSuccessBankAccount, setIsShowModalSuccessBankAccount] =
		useState(false);
	const [isShowModalCheckBankAccount, setIsShowModalCheckBankAccount] =
		useState(false);
	const [successEditInfo, setSuccessEditInfo] = useState(false);

	useEffect(() => {
		getBankList();
	}, []);

	useEffect(() => {
		readUserInfo();
	}, [user, bankList]);

	/**
	 * 포트원 은행 리스트 조회
	 * @returns {Promise<void>}
	 * [{
	 * code: 은행코드
	 * name: 은행명
	 * }]
	 */
	const getBankList = async () => {
		setIsLoading(true);
		try {
			const res = await partnersApi.readBankList();
			if (res.code === 200) {
				let list = res.result;
				setBankList(list);
			}
		} catch (e) {
			console.log("e", e);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * 사용자 기본 정보 조회하기
	 * @type {(function(): Promise<void>)|*}
	 */
	const readUserInfo = useCallback(async () => {
		if (!bankList.length) return;
		setIsLoading(true);
		try {
			const param = {
				userId: user?.id,
				type: "basic_info",
			};
			const res = await api.getUserInfoByType(param);
			if (res.data.code === 200) {
				const { name: bankName } = bankList.find(
					(v) => v.code === res.data.result.bank_code
				);
				let birthDayStr = res.data.result?.encrypt_birthday?.replaceAll(
					"-",
					""
				);
				if (birthDayStr.length === 8) {
					birthDayStr = birthDayStr.substring(2);
				}
				setCurrUserValue({
					...res.data.result,
					birth_day: birthDayStr,
				});
				setFormValue({
					name: res.data.result.name,
					phone: res.data.result.phone,
					bank_code: res.data.result.bank_code,
					bank_name: bankName,
					account_number: res.data.result.account_number,
					account_holder_name:
						res.data.result.account_holder_name ?? res.data.result.name,
					is_verify_account: !!(
						res.data.result.account_number && res.data.result.bank_code
					),
				});
			}
		} catch (e) {
			console.log("readUserInfo :: error = ", e);
		} finally {
			setIsLoading(false);
		}
	}, [user, bankList]);

	/**
	 * 계좌인증
	 * @param type account-입력한 계좌번호로 1원 전송하기 / code-1원 전송한 계좌 코드 입력하기
	 * @returns {Promise<void>}
	 */
	const verifyAccount = async (type = "account") => {
		try {
			setSubmitBankLoading(true);
			let param = {
				...formValue,
				type: type,
				user_id: user?.id,
			};
			if (type === "account") {
				param["name"] = formValue.name;
				param["birthday"] = currUserValue.birth_day;
			} else {
				param["code"] = checkBankAccountNumber;
			}
			const res = await partnersApi.verifyAccount(param);
			if (res.data.code === 200) {
				if (type === "account") {
					setIsShowModalCheckBankAccount(true);
					setCheckBankAccountNumber(null);
				} else {
					setIsShowModalSuccessBankAccount(true);
					setCheckBankAccountNumber(undefined);
					// 계좌 소유자 본명으로 처리
					setFormValue({
						...formValue,
						account_holder_name: formValue.name,
						is_verify_account: true,
					});
				}
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `${res.data.msg}`,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
			}
		} catch (e) {
			console.log("verifyAccount :: e = ", e);
		} finally {
			setSubmitBankLoading(false);
		}
	};

	/**
	 * 폼 입력창 handler
	 * @param e
	 */
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "phone") {
			if (value?.length <= 11) {
				setFormValue({
					...formValue,
					[name]: value?.replaceAll(/[^0-9]/g, ""),
				});
			}
		} else if (name === "account_number") {
			if (formValue.is_verify_account) {
				if (currUserValue && currUserValue?.account_number) {
					if (currUserValue.account_number === value) {
						setFormValue({
							...formValue,
							[name]: value,
						});
					} else {
						setFormValue({
							...formValue,
							[name]: value,
							account_holder_name: "",
							is_verify_account: false,
						});
					}
				} else {
					if (formValue.account_number === value) {
						setFormValue({
							...formValue,
							[name]: value,
						});
					} else {
						setFormValue({
							...formValue,
							[name]: value,
							account_holder_name: "",
							is_verify_account: false,
						});
					}
				}
			} else {
				setFormValue({
					...formValue,
					[name]: value,
				});
			}
		} else {
			setFormValue({
				...formValue,
				[name]: value,
			});
		}
	};

	/**
	 * 정보 수정 취소하기
	 */
	const handleCancel = () => {
		if (
			formValue.name ||
			formValue.phone ||
			formValue.bank_code ||
			formValue.account_number ||
			formValue.account_holder_name
		) {
			// 입력한 값이 하나라도 있으면 취소하겠냐는 팝업 띄우기
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `작성중인 내용이 모두 사라집니다.\n정말 취소하시겠습니까?`,
				showCancelButton: true,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
				cancelButtonText: "취소",
				cancelButtonColor: "#FFFFFF",
			}).then((res) => {
				if (res.isConfirmed) {
					setFormValue(initialFormValue);
					window.scrollTo(0, 0);
					setActive(1);
				}
			});
		} else {
			setFormValue(initialFormValue);
			window.scrollTo(0, 0);
			setActive(1);
		}
	};

	/**
	 * 정보 수정하기
	 */
	const handleClick = async () => {
		// 정보 수정하기 위한 값이 전부 유효한지 확인
		const valid = isValidForm();

		if (valid) {
			setFormLoading(true);
			try {
				let param = {
					...formValue,
					id: user?.id,
					key: "basic_profile_info",
				};

				const res = await api.editUserByKey(param);

				if (res.data.code === 200) {
					dispatch(
						setUser({
							...user,
							bank_code: res.result?.bank_code,
							account_number: res.result?.account_number,
							name: res.result?.name ? res.result?.name : formValue.name,
							phone: res.result?.phone,
							account_holder_name: res.result?.account_holder_name,
						})
					);
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: "저장되었습니다.",
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					}).then(() => {
						setFormValue(initialFormValue);
						window.scrollTo(0, 0);
						setActive(1);
					});
				} else {
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `일시적인 장애로 새로고침 후 다시 시도해주세요.`,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					});
				}
			} catch (e) {
				console.log("handleClick :: error = ", e);
			} finally {
				setFormLoading(false);
			}
		}
	};

	/**
	 * 로그아웃
	 * @returns {Promise<void>}
	 */
	const logout = async () => {
		setIsLoading(true);
		Swal.fire({
			customClass: "alert-custom-confirm",
			text: `정말 로그아웃 하시겠습니까?`,
			showCancelButton: true,
			confirmButtonText: "확인",
			confirmButtonColor: "#117FFA",
			cancelButtonText: "취소",
			cancelButtonColor: "#FFFFFF",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const param = {
					id: user.id,
				};
				await partnersApi.logout(param);
				localStorage.removeItem("admin:accessToken");
				localStorage.removeItem("admin:refreshToken");

				dispatch(setChannelUser(null));
				dispatch(setUser(null));
				setIsLoading(false);
				history.push(`/`);
			}
		});
		setIsLoading(false);
	};

	/**
	 * 정보 저장 버튼 Disabled
	 * @returns {boolean}
	 */
	const isDisabled = () => {
		if (
			formValue.name?.trim() ||
			formValue.phone?.trim() ||
			formValue.bank_code?.trim() ||
			formValue.account_number?.trim() ||
			formValue.account_holder_name?.trim()
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * 정보 수정 내용 유효성 검사
	 * @returns {boolean}
	 */
	const isValidForm = () => {
		// step 1. 모든 항목에 입력값이 있는지 확인하기
		if (
			formValue.name?.trim() &&
			formValue.phone?.trim() &&
			formValue.bank_code?.trim() &&
			formValue.account_number?.trim()
		) {
			// step 2. 전화번호 형식에 맞는지 확인하기
			if (phonePattern.exec(formValue.phone)) {
				// step 3. 인증된 계좌인지 확인하기
				if (formValue.is_verify_account) {
					return true;
				} else {
					Swal.fire({
						customClass: "alert-custom-confirm",
						text: `입력하신 계좌를 인증해주세요.`,
						showCancelButton: false,
						confirmButtonText: "확인",
						confirmButtonColor: "#117FFA",
					});
					return false;
				}
			} else {
				Swal.fire({
					customClass: "alert-custom-confirm",
					text: `휴대폰 형식에 맞춰서 입력해주세요.`,
					showCancelButton: false,
					confirmButtonText: "확인",
					confirmButtonColor: "#117FFA",
				});
				return false;
			}
		} else {
			Swal.fire({
				customClass: "alert-custom-confirm",
				text: `정보를 수정하기 위한 필수 입력 사항들을 채워주세요.`,
				showCancelButton: false,
				confirmButtonText: "확인",
				confirmButtonColor: "#117FFA",
			});
			return false;
		}
	};

	/**
	 * 은행 목록화
	 * @type {unknown[]|undefined|*[]}
	 */
	const items =
		bankList?.length > 0
			? bankList?.filter(isGeneralBank)?.map((item) => ({
					label: item.name,
					key: item.code,
					icon: (
						<img
							src={`/assets/images/bank/${item.code}.png`}
							style={{
								width: 16,
								height: 16,
							}}
							onError={(e) => {
								e.currentTarget.style.visibility = "hidden";
							}}
						/>
					),
				}))
			: [];

	/**
	 * 은행 목록 선택하기 handler
	 * @param key
	 */
	const onClick = ({ key }) => {
		let clickBank = bankList.filter((item) => item.code === key);
		if (clickBank?.[0]?.code) {
			if (formValue.is_verify_account) {
				if (currUserValue && currUserValue?.bank_code) {
					if (currUserValue.bank_code !== clickBank[0].code) {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
							account_holder_name: "",
							is_verify_account: false,
						});
					} else {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
						});
					}
				} else {
					if (formValue.bank_code !== clickBank[0].code) {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
							account_holder_name: "",
							is_verify_account: false,
						});
					} else {
						setFormValue({
							...formValue,
							bank_code: clickBank[0].code,
							bank_name: clickBank[0].name,
						});
					}
				}
			} else {
				setFormValue({
					...formValue,
					bank_code: clickBank[0].code,
					bank_name: clickBank[0].name,
				});
			}
		}
	};

	return (
		<div className="max-width-apply">
			{!!isLoading && (
				<div
					style={{
						width: "100%",
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 999,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(0,0,0,0.2)",
					}}
				>
					<Spin size={"large"} />
				</div>
			)}
			<div className="calculate-inquiry-title" style={{ marginBottom: 30 }}>
				내 정보
			</div>
			<div className="calculate-inquiry-table" style={{ marginBottom: "55px" }}>
				<div className="calculate-inquiry-add-wrapper">
					<div className="calculate-inquiry-add-wrap">
						<div className="calculate-inquiry-add-wrap-title">
							성함
							<span>*</span>
						</div>
						<div>
							<Input
								type="text"
								placeholder="성함을 입력해주세요"
								name={"name"}
								value={formValue.name}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="calculate-inquiry-add-wrap">
						<div className="calculate-inquiry-add-wrap-title">
							휴대폰 번호
							<span>*</span>
						</div>
						<div>
							<Input
								type="text"
								placeholder="-를 생략하고 입력해주세요."
								name={"phone"}
								value={formValue.phone}
								onChange={handleChange}
							/>
						</div>
					</div>
					{/* 은행 */}
					<div className="calculate-inquiry-add-wrap">
						<div className="calculate-inquiry-add-wrap-title">
							은행
							<span>*</span>
						</div>
						<div>
							<Dropdown
								overlayClassName={`bank-dropdown-wrap`}
								menu={{
									items,
									onClick,
								}}
								trigger={["click"]}
								overlayStyle={{
									maxHeight: 200,
									overflow: "auto",
								}}
							>
								<Input
									loading={formLoading}
									placeholder={"은행을 선택해주세요"}
									value={formValue.bank_name}
								/>
							</Dropdown>
						</div>
					</div>
					{/* 계좌번호 */}
					<div
						className="calculate-inquiry-add-wrap"
						style={
							checkBankAccountNumber !== undefined
								? {
										height: "120%",
										flexWrap: "wrap",
										paddingTop: "20px",
										paddingBottom: "20px",
										position: "relative",
									}
								: { flexWrap: "wrap", width: "100%" }
						}
					>
						<div
							style={{ width: "100%", display: "flex", alignItems: "center" }}
						>
							<div className="calculate-inquiry-add-wrap-title">
								계좌 번호
								<span>*</span>
							</div>
							<div
								style={{
									display: "flex",
									width: "500px",
									alignItems: "center",
									gap: "10px",
								}}
							>
								<Input
									type="text"
									placeholder="-를 생략하고 입력해주세요."
									name={"account_number"}
									value={formValue.account_number}
									onChange={handleChange}
									style={{
										width: "380px",
									}}
								/>
								{formValue.is_verify_account ? (
									<div
										className="input-div-button-success"
										style={{
											padding: "10px",
											width: "18%",
											minWidth: "105px",
										}}
									>
										<img
											src={`/assets/images/success_button_icon.png`}
											alt={`success_button_icon`}
											style={{
												width: "1em",
												height: "auto",
											}}
										/>
										<span>계좌인증</span>
									</div>
								) : (
									<Button
										className="certification-request-btn"
										onClick={() => {
											if (!submitBankLoading) {
												verifyAccount("account");
											}
										}}
										loading={submitBankLoading}
										disabled={
											!formValue.name.length ||
											!formValue.bank_code ||
											!formValue.account_number ||
											submitBankLoading
										}
									>
										{!submitBankLoading && "인증요청"}
									</Button>
								)}
							</div>
						</div>

						{checkBankAccountNumber !== undefined &&
							!formValue.is_verify_account && (
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										marginTop: "12px",
									}}
								>
									<div className="calculate-inquiry-add-wrap-title">
										인증 번호
									</div>
									<div
										style={{
											display: "flex",
											width: "500px",
											justifyContent: "space-between",
											gap: "10px",
										}}
									>
										<Input
											type="text"
											placeholder="4자리 숫자를 입력해주세요."
											value={checkBankAccountNumber}
											onChange={(e) => {
												if (e.target.value?.length <= 4) {
													setCheckBankAccountNumber(
														e.target.value?.replace(/[^0-9]/g, "")
													);
												}
											}}
											style={{
												width: "380px",
											}}
										/>
										<Button
											onClick={() => {
												if (!submitBankLoading) {
													verifyAccount("code");
												}
												// setIsShowModalCheckBankAccount(true);
												// setCheckBankAccountNumber(null);
											}}
											loading={submitBankLoading}
											disabled={
												!formValue.bank_code ||
												!formValue.account_number ||
												!checkBankAccountNumber
											}
											className="certification-request-btn"
										>
											{!submitBankLoading && "확인"}
										</Button>
									</div>
								</div>
							)}
						{checkBankAccountNumber !== undefined &&
							!formValue.is_verify_account && (
								<img
									src={`/assets/images/bank_account_image.png`}
									alt={`bank_account_image`}
									style={{
										width: "16em",
										height: "auto",
										position: "absolute",
										right: -40,
									}}
								/>
							)}
					</div>
					<div className="calculate-inquiry-add-wrap">
						<div className="calculate-inquiry-add-wrap-title">
							계좌 소유자
							<span>*</span>
						</div>
						<div>
							<Input
								type="text"
								placeholder="계좌 소유자를 입력해주세요."
								name={"account_holder_name"}
								value={formValue.account_holder_name}
								// onChange={handleChange}
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="calculate-inquiry-desc-text">
				*<span>계좌번호 오기입</span>으로 인해 다른계좌로 송금 시
				당영투게더에서는 책임지지 않습니다.
			</div>
			<div className="calculate-inquiry-button-wrap">
				<Button
					className="calculate-inquiry-cancel-button"
					onClick={handleCancel}
				>
					취소
				</Button>
				<Button
					className="calculate-inquiry-write-button"
					type={"primary"}
					disabled={!isDisabled() || formLoading}
					onClick={() => {
						if (!formLoading) {
							handleClick();
						}
					}}
				>
					저장
				</Button>
			</div>
			<Row justify={"center"}>
				<button
					className={`channel-withdraw`}
					style={{ color: "#ced4da" }}
					onClick={logout}
				>
					로그아웃
				</button>
			</Row>
			{/* 모달 - 1원 입금 */}
			<Modal
				title=""
				open={isShowModalCheckBankAccount}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title" style={{ marginBottom: "10px" }}>
					입력하신 계좌로 1원을 보냈습니다.
				</div>
				<div
					className="modal-title"
					style={{ marginBottom: "-4px", paddingTop: 0 }}
				>
					거래내역 내 입금자명에 적힌
				</div>
				<div
					className="modal-title"
					style={{ paddingTop: 0, marginBottom: "20px" }}
				>
					<span>숫자 4자리</span>를 확인하여 입력해주세요.
				</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setIsShowModalCheckBankAccount(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
			{/* 모달 - 계좌 인증 완료 */}
			<Modal
				title=""
				open={isShowModalSuccessBankAccount}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title">계좌인증이 완료되었습니다.</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => setIsShowModalSuccessBankAccount(false)}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
			<Modal
				title=""
				open={successEditInfo}
				footer={<></>}
				closable={false}
				wrapClassName="modal-wrap seller-grade-modal-wrap"
				width={400}
			>
				<div className="modal-title">저장되었습니다.</div>
				<div className="modal-button-wrap">
					<div
						onClick={() => {
							setSuccessEditInfo(false);
							history.replace("/dashboard");
						}}
						className="modal-button-success"
						style={{ width: "50%" }}
					>
						확인
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default EditInfo;
